// store.js

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: null,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    clearUserInfo(state) {
      state.userInfo = null;
    },
  },
  actions: {
    login({ commit }, { username, password }) {
      // 假设这里进行了登录验证，验证通过后保存用户信息到 Vuex Store
      const userInfo = { username, token: 'your_access_token' };
      commit('setUserInfo', userInfo);
    },
    logout({ commit }) {
      // 登出时清除 Vuex Store 中的用户信息
      commit('clearUserInfo');
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.userInfo,
    getUserInfo: (state) => state.userInfo,
  },
});
