<template>
  <el-dialog
    title="标签"
    :visible.sync="visible"
    width="1200px"
    center
    @close="closeVisible"
    :show-close="false"
    v-loading="loading"
    :close-on-click-modal="false"
  >
    <div class="left flex align-center" @click="updateVisible">
      <el-image
        class="arrow"
        :src="require('@/static/images/left-arrow.png')"
        lazy
      ></el-image>
      <p class="mg-l-20">返回</p>
    </div>
    <!-- <div class="right">
      <el-button type="primary" @click="submitTags">保存</el-button>
    </div> -->
    <div class="main">
      <div class="flex line">
        <span>项目名称：</span>
        <span style="color: #111; font-size: 26px; font-weight: bold">{{
          projectName
        }}</span>
      </div>
      <div class="flex line">
        <span>关联标签：</span>
        <div class="flex">
          <el-input v-model="addNewTag" maxlength="5" show-word-limit />
          <el-button class="add" @click="addNew" v-if="!isDel">新增历史标签</el-button>
          <el-button class="del" @click="delTag" v-if="!isDel">删除历史标签</el-button>
          <el-button class="del" @click="delTag2" v-if="isDel">完成删除</el-button>
        </div>
      </div>
      <p style="font-size: 14px; color: #999; margin: 10px 0">
        选中标签视为关联（可多选，上限6），未选中则未关联
      </p>
      <div class="tag" v-if="tagsList.length > 0" @click.stop>
        <div v-if="isDel" class="operate">
          <a href="#" v-for="(item, index) in tagsList" :key="index">
            {{ item.name }}
            <span @click.stop="submitDel(item.id)">
              <img class="delT" src="../../../static/images/close_red.png" alt="" />
            </span>
          </a>
        </div>
        <el-checkbox-group v-else @change="getVal" v-model="checkedTags" :max="6">
          <el-checkbox
            native-event.stop
            @change="getItem(item.id)"
            v-for="item in tagsList"
            :label="item.id"
            :key="item.id"
            >{{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <el-dialog
        title=""
        :visible.sync="confirmDel"
        width="40%"
        center
        :append-to-body="true"
      >
        <p
          style="font-size: 24px; text-align: center; margin-top: 0; margin-bottom: 20px"
        >
          确定删除该标签？
        </p>
        <p style="margin-top: 30px; color: red; text-align: center; margin-bottom: 30px">
          删除后，会影响所有关联此项目的标签
        </p>
        <p class="text-center">
          <span slot="footer" class="dialog-footer text-center">
            <el-button type="primary" @click="submitConfirm">确 定</el-button>
            <el-button @click="confirmDel = false">取 消</el-button>
          </span>
        </p></el-dialog
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    projectName: {
      type: String,
      default: "",
    },
    tagsList: {
      type: Array,
      default: [],
    },
    hasTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isVisible: false,
      addNewTag: "",
      confirmDel: false,
      checkedTags: [],
      isDel: false,
      id: "",
      ten: [],
      loading: false,
    };
  },
  mounted() {
    console.log(this.visible);
    this.isVisible = this.visible;
    console.log("has", this.hasTags);
  },
  watch: {
    tagsList(n) {
      this.loading = false;
      console.log("所有标签", n);
    },
    hasTags(n) {
      this.checkedTags = n;
      this.ten = n;
      console.log("项目标签", n);
    },
  },
  methods: {
    getItem(v) {
      console.log(v);
      if (this.ten.includes(v)) {
        this.$emit("updateTags", [false, v]);
      } else {
        this.$emit("updateTags", [true, v]);
      }
    },
    // 选择标签
    getVal(v) {
      // if (this.ten.includes(v[0])) {
      //   this.$emit('updateTags', false)
      // } else {
      //   this.$emit('updateTags', true)
      // }
      // console.log(this.checkedTags)
      // console.log(this.ten)
    },
    updateVisible() {
      this.isDel = false;
      this.$emit("updateVisible", false);
    },
    closeVisible() {
      this.$emit("updateVisible", false);
    },
    addNew() {
      this.loading = true;
      this.$emit("addTag", this.addNewTag);
      this.addNewTag = "";
    },
    // submitTags 保存打标签
    submitTags() {
      this.$emit("submitTags", this.checkedTags);
    },
    // 删除打标签
    submitDel(id) {
      this.confirmDel = true;
      this.id = id;
    },
    submitConfirm() {
      this.$emit("submitDel", this.id);
      this.confirmDel = false;
    },
    delTag() {
      this.isDel = true;
    },
    delTag2() {
      this.isDel = false;
      this.$emit("returnStatus");
    },
  },
};
</script>
<style lang="scss" scoped>
.left {
  position: absolute;
  top: 22px;
  left: 20px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  color: #000;
  .arrow {
    width: 10px;
    height: 20px;
    font-size: 18px;
  }
}
.right {
  position: absolute;
  top: 22px;
  right: 20px;
}
.add,
.add:focus,
.add:hover {
  background: #000;
  color: #fff;
  margin-left: 10px;
  border-color: #000;
}
.del,
.del:focus,
.del:hover {
  background: red;
  color: #fff;
  border-color: red;
  margin-left: 10px;
}
.del:nth-child(2),
.del:nth-child(2):focus,
.del:nth-child(2):hover {
  background: #41c68e;
  border-color: #41c68e;
}
span {
  font-size: 22px;
}
.line {
  margin-bottom: 20px;
}

.operate a {
  width: 125px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  background: #f6f6f6;
  border-radius: 10px;
  margin-right: 30px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  color: #41c68e;
}
::v-deep .el-checkbox {
  margin-right: 30px;
  margin-bottom: 20px;
}
::v-deep .el-checkbox__inner {
  display: none !important;
}
::v-deep .el-checkbox__label {
  padding-left: 0;
  width: 125px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  background: #f6f6f6;
  border-radius: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  position: relative;
  font-size: 22px;
  font-weight: bold;
  color: #41c68e !important;
}
.delT {
  width: 15px;
  height: 15px;
  position: absolute;
  right: -7px;
  top: -7px;
}
::v-deep .el-checkbox.is-checked .el-checkbox__label {
  background: #41c68e;
  color: #fff !important;
}
.el-button--primary,
.el-button--primary:hover {
  background: #41c68e;
  border-color: #41c68e;
}
</style>
