<template>
  <div class="info">
    <div class="nav flex space-between align-center">
      <div class="left flex align-center">
        <p>商品介绍</p>
      </div>
      <div class="right mg-r-20" v-if="Object.keys(info).length > 0">
        <el-button type="primary" @click="sendVisible = true">购买</el-button>
      </div>
    </div>
    <div class="infomation" v-if="Object.keys(info).length > 0">
      <div class="imgBox">
        <div class="img flex space-center">
          <el-image class="flower" :src="info.materialDO.img" lazy></el-image>
        </div>
      </div>
      <div class="text">
        <div class="flex">
          <div>
            <p class="label">名称：</p>
            <b style="font-size: 17px">{{ info.materialDO.name }}</b>
          </div>
          <div style="margin-left: 100px">
            <p class="label">属性：</p>
            <b style="font-size: 17px">{{ info.materialDO.categoryName }}</b>
          </div>
          <div style="margin-left: 100px">
            <p class="label">价格：</p>
            <b style="font-size: 17px; color: #ffb800"
              >{{ info.salePrice }} {{ info.salePriceUnit == 2 ? "信用点" : "火种" }}</b
            >
          </div>
        </div>
        <div>
          <div class="flex">
            <p class="label">简介：</p>
            <p class="pro" style="color: #666">
              {{ info.materialDO.blurb }}
            </p>
          </div>
        </div>
        <div>
          <div class="flex align-center">
            <p class="label">歌语：</p>
            <p class="pro">
              <b
                style="font-size: 16px; color: #41c68e"
                v-html="info.materialDO.songLan"
              ></b>
            </p>
          </div>
        </div>
        <div class="flex">
          <div v-if="info.type == 2" class="flex align-center">
            <p class="label">掉落材料：</p>
            <b style="font-size: 17px">{{ info.materialDO.materialName }}</b>
          </div>
          <div
            class="flex align-center"
            :style="{ marginLeft: info.type == 2 ? '100px' : '0' }"
          >
            <p class="label" style="">材料售卖价值：</p>
            <b style="font-size: 17px"
              >{{ info.materialDO.saleCreditPrice || info.materialDO.price }}
              {{ info.salePriceUnit == 2 ? "信用点" : "火种" }}</b
            >
          </div>
        </div>
        <!-- <div>
          <p>
            <span class="label">变种：</span>
          </p>
        </div> -->
      </div>
    </div>
    <div v-else>
      <el-empty description="请先选择"></el-empty>
    </div>
    <el-dialog title="" :visible.sync="sendVisible" width="40%" center>
      <p style="font-size: 24px; text-align: center">
        确定以<span style="color: #ffb800; font-size: 20px"
          >{{ info.salePrice || info.materialDO.price }}信用点</span
        >购买该{{ info.type == 1 ? "材料" : info.type == 2 ? "种子" : "皮肤" }}？
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBuy">确 定</el-button>
        <el-button @click="sendVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "seedInfo",
  props: {
    detail: {
      type: Object,
      default: {},
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      sendVisible: false,
      seedVisible: false,
      info: {},
    };
  },
  created() {},
  watch: {
    detail(n, o) {
      this.info = {};
      this.info = n;
    },
  },
  methods: {
    confirmBuy() {
      this.$post("/sj/purchaseCommodity", {
        commodityType: this.type,
        commodityId: this.detail.id,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("购买成功！");
          this.sendVisible = false;
          this.$emit("updateMember");
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    changeTab(i) {
      this.current = i;
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  height: 100%;
  .nav {
    .arrow {
      width: 10px;
      height: 20px;
      font-size: 18px;
    }
    .left {
      cursor: pointer;
      p {
        font-weight: bold;
      }
    }
    .sold {
      background: none !important;
      color: #41c68e;
    }
  }
  .flower {
    width: 200px;
  }
  .infomation {
    height: calc(100% - 36px);
    overflow-y: auto;
    padding-right: 20px;
    box-sizing: border-box;
    padding-bottom: 20px;
    .imgBox {
      margin: 20px;
      .img .el-image {
        border: 1px solid #eee;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
      }
    }
    .text {
      margin-top: 20px;
    }
    div {
      margin-top: 20px;

      p.label {
        display: inline-block;
        width: 100px;
        color: #999;
      }
      .pro {
        line-height: 26px;
        flex: 1;
      }
    }
  }
}
::v-deep .el-button--primary {
  background-color: #41c68e !important;
  border-color: #41c68e !important;
  padding: 10px 20px;
}
.el-button + .el-button {
  margin-left: 20px;
}
</style>
