import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Home from "@/views/home";
import Profile from "@/views/my/profile.vue";
import Seed from '@/views/my/page-list/seed.vue'
import SeedInfo from '@/views/my/page-list/seedInfo.vue'
import Shop from "@/views/shop/index.vue";
import Project from "@/views/project/index.vue";
import ProjectList from "@/views/project/page-list/projectList.vue";
import ProjectInfo from "@/views/project/page-list/projectInfo.vue";
import CreateProject from "@/views/project/page-list/createProject.vue";
import AboutUs from '@/views/aboutUs/index.vue'
import School from '@/views/school/index.vue'
import MyShareList from '@/views/school/myShareList.vue'
import ToShare from '@/views/school/toShare.vue'
import Info from '@/views/school/info.vue'


import Flower from "@/views/flower/index.vue";



const routes = [
  //路由重定向
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/profile",
    component: Profile,
    redirect: '/profile/seed',
    children: [
      {
        path: '/profile/seed',
        component: Seed
      },
      {
        path: '/profile/seedInfo',
        component: SeedInfo
      }
    ]
  },
  {
    path: "/shop",
    component: Shop
  },
  {
    path: "/school",
    component: School,
  },
  {
    path: '/school/myShareList',
    component: MyShareList
  },
  {
    path: '/school/toShare',
    component: ToShare
  },
  {
    path: '/school/info',
    component: Info
  },
  {
    path: "/project",
    component: Project,
    redirect: '/project/page-list/projectList',
    children: [
      {
        path: '/project/page-list/projectList',
        component: ProjectList
      },
      {
        path: '/project/page-list/projectInfo',
        component: ProjectInfo
      },
      {
        path: '/project/page-list/createProject',
        component: CreateProject
      }
    ]
  },
  {
    path: "/flower",
    component: Flower
  },
  {
    path: "/aboutUs",
    component: AboutUs
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
