<template>
  <div class="info">
    <div class="nav flex space-between align-center">
      <div class="left flex align-center" @click="$router.go(-1)">
        <el-image
          class="arrow"
          :src="require('@/static/images/left-arrow.png')"
          lazy
        ></el-image>
        <p class="mg-l-20">返回</p>
      </div>
      <div class="right">
        <el-button class="mg-l-20" type="primary" @click="validateProject">{{
          $route.query.is ? "保存" : "开始"
        }}</el-button>
      </div>
    </div>
    <div>
      <div class="flex mg-t-20 align-center">
        <span class="bold">主题：</span>
        <el-input
          v-model="title"
          :disabled="$route.query.is == 'edit'"
          class="mainTitle"
          type="text"
          maxlength="20"
          show-word-limit
        ></el-input>
      </div>
      <div class="flex mg-t-20 align-center mg-b-40">
        <span class="bold">计划：</span>
        <el-input
          v-model="tag"
          class="mg-r-20 node"
          type="text"
          maxlength="15"
          show-word-limit
        ></el-input>
        <el-button type="primary" class="add" @click="addTag">添加</el-button>
      </div>
      <div class="flex mg-t-20 flex-wrap">
        <el-tag
          v-for="(item, index) in nodeNames"
          :key="index"
          closable
          type="info"
          class="text-center mg-b-20"
          @close="handleCloseTag(index)"
          >{{ item.nodeName }}</el-tag
        >
      </div>
      <h1 v-if="finishNode.length > 0" style="font-size: 28px; margin-top: 30px">
        已完成计划
      </h1>
      <div class="flex mg-t-20 flex-wrap" v-if="finishNode.length > 0">
        <el-tag
          v-for="(item, index) in finishNode"
          :key="index"
          closable
          type="success"
          class="text-center mg-b-20"
          @close="handleCloseTag(index)"
          >{{ item.nodeName }}</el-tag
        >
      </div>
    </div>
    <el-dialog title="" :visible.sync="seedVisible" width="40%" center>
      <p style="font-size: 30px; text-align: center">
        确定{{ $route.query.is ? "保存" : "开始" }}这个项目？
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addProject">确 定</el-button>
        <el-button @click="seedVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "createProject",
  data() {
    return {
      seedVisible: false,
      list: [""],
      memberId: "",
      title: "",
      tag: "",
      nodeNames: [],
      finishNode: [],
      info: {},
    };
  },
  created() {
    this.memberId = JSON.parse(localStorage.getItem("userId"));
    if (this.$route.query.type == "parent" && this.$route.query.is) {
      this.$get(`/wd/getProjectDetail?projectId=${this.$route.query.id}`).then((res) => {
        this.title = res.data.data.title;
        res.data.data.nodeList.map((item) => {
          if (item.status != 2) {
            this.nodeNames.push(item);
          } else {
            this.finishNode.push(item);
          }
        });
        this.nodeNames.map((item) => {
          item.nodeName = item.name;
        });
        this.finishNode.map((item) => {
          item.nodeName = item.name;
        });
      });
    } else if (this.$route.query.type == "child" && this.$route.query.is) {
      this.$get(`/project_sub/getProjectDetail?projectId=${this.$route.query.id}`).then(
        (res) => {
          this.title = res.data.data.title;
          res.data.data.nodeList.map((item) => {
            if (item.status != 2) {
              this.nodeNames.push(item);
            } else {
              this.finishNode.push(item);
            }
          });
          this.nodeNames.map((item) => {
            item.nodeName = item.name;
          });
          this.finishNode.map((item) => {
            item.nodeName = item.name;
          });
        }
      );
    }
  },
  methods: {
    addTag() {
      if (this.tag == "") {
        this.$message.error("请输入节点名称");
        return false;
      }
      let obj = {};
      obj.nodeName = this.tag;
      this.nodeNames.push(obj);
      this.tag = "";
    },
    handleCloseTag(index) {
      this.nodeNames.splice(index, 1);
    },
    validateProject() {
      if (this.title == "") {
        this.$message.error("请输入主题");
        return false;
      }
      if (this.nodeNames.length < 2 || this.nodeNames.length > 9) {
        this.$message.error("限制2-9个节点");
        return false;
      }
      this.seedVisible = true;
    },
    addProject() {
      if (this.$route.query.is == "edit") {
        let arr = this.nodeNames.concat(this.finishNode);
        arr.map((item) => {
          item.name = item.nodeName;
        });
        this.$post("/wd/editeProjectNodeList", {
          projectId: this.$route.query.id,
          projectNodeList: arr,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("编辑成功！");
            this.seedVisible = false;
            this.$router.go(-1);
          } else {
            this.$message.error(res.data.errorMessage);
          }
        });
      } else {
        if (this.$route.query.type == "parent") {
          this.$post("/wd/addProject", {
            title: this.title,
            memberId: this.memberId,
            nodeNames: this.nodeNames,
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("创建成功！");
              this.seedVisible = false;
              this.$router.push("/project/page-list/projectList");
            } else {
              this.$message.error(res.data.errorMessage);
            }
          });
        } else if (this.$route.query.type == "createChildItem") {
          this.$post("/project_sub/addProject", {
            parentId: this.$route.query.parentId,
            title: this.title,
            memberId: this.memberId,
            nodeNames: this.nodeNames,
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("创建成功！");
              this.seedVisible = false;
              this.$router.push("/project/page-list/projectList");
            } else {
              this.$message.error(res.data.errorMessage);
            }
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  height: 100%;
  .nav {
    .left {
      cursor: pointer;
    }
    .arrow {
      width: 10px;
      height: 20px;
      font-size: 18px;
    }
  }
  .el-input {
    width: 500px;
  }
}
::v-deep .mainTitle .el-input__inner {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #aaa;
}
::v-deep .node .el-input__inner {
  border-radius: 0;
  border: none;
  background: #f7f7f8;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  background: none;
}
.el-tag {
  width: 30%;
  padding: 10px 0;
  height: auto;
  font-size: 16px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: none;
}
.el-tag + .el-tag {
  margin-left: 20px;
}
.el-tag:nth-child(3n + 1) {
  margin-left: 0;
}
.el-tag.el-tag--info {
  color: #000;
  position: relative;
  word-break: break-all;
  white-space: break-spaces;
  padding: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-tag .el-icon-close {
  top: -5px;
  right: -5px;
  position: absolute;
  width: 17px;
  height: 17px;
  line-height: 17px;
}
::v-deep .add.el-button--primary {
  background-color: #000 !important;
  border-color: #000 !important;
  padding: 5px 12px;
}
::v-deep .el-tag .el-icon-close::before {
  background: red;
  border-radius: 50%;
  color: #fff;
}
::v-deep .el-tag.el-tag--success .el-icon-close::before {
  display: none;
}
::v-deep .el-tag.el-tag--success {
  background: linear-gradient(53deg, #ffb800 0%, #ff8b04 31.26%, #ed0404 97.45%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .finish.el-button--primary {
  margin-right: 20px;
  background: linear-gradient(
    53deg,
    #ffb800 0%,
    #ff8b04 31.26%,
    #ed0404 97.45%
  ) !important;
  border-color: transparent !important;
}
</style>
