import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from 'axios';
import 'element-ui/lib/theme-chalk/index.css';
import { get, post, put, del } from '@/utils/request'
Vue.config.productionTip = false
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$put = put
Vue.prototype.$del = del
Vue.prototype.$axios = axios


Vue.use(ElementUI);

console.log(this)


router.beforeEach((to, from, next) => {
console.log(to)
if (to.path == '/home' && localStorage.getItem("token")) {
  console.log(2)
  next('/project/page-list/projectList');
} else {
  next()
}
  // if (Boolean(localStorage.getItem("token"))) { // 判断是否登录
  //   console.log('登录了', 5)
  //           next({
  //               path: '/project/page-list/projectList'
  //           })
       
  //   } else {
  //   console.log('mei', 5)

        next();
    // }

})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
