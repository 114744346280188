<template>
  <div class="main">
    <div class="content">
      <div class="info">
        <div class="nav flex space-between align-center">
          <div
            class="left flex align-center"
            @click="$router.push('/school/myShareList')"
          >
            <el-image
              class="arrow"
              :src="require('@/static/images/left-arrow.png')"
              lazy
            ></el-image>
            <p class="mg-l-20">返回</p>
          </div>
          <div class="right">
            <el-button class="mg-l-20" type="primary" @click="dialogVisible = true"
              >保存</el-button
            >
          </div>
        </div>
        <div>
          <div class="flex">
            <div class="flex mg-t-10 align-center" style="width: 40%">
              <span class="bold">主题：</span>
              <el-input
                v-model="title"
                class="mainTitle"
                type="text"
                maxlength="15"
                show-word-limit
              ></el-input>
            </div>
            <div class="flex mg-t-10 align-center" style="width: 40%">
              <span class="bold">标签：</span>
              <el-input
                v-model="tag"
                class="mainTitle"
                type="text"
                maxlength="5"
                show-word-limit
              ></el-input>
            </div>
          </div>
          <div class="flex mg-t-20 mg-b-40" style="align-item: start">
            <span class="bold">简介：</span>
            <el-input
              v-model="desc"
              class="mg-r-20 node"
              type="textarea"
              maxlength="200"
              rows="5"
              show-word-limit
            ></el-input>
          </div>
        </div>
        <el-dialog title="" :visible.sync="dialogVisible" width="40%" center>
          <p
            style="
              font-size: 24px;
              text-align: center;
              color: #41c68e;
              margin-bottom: 10px;
            "
          >
            保存成功！
          </p>
          <p style="font-size: 24px; text-align: center">是否分享该项目项目？</p>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="shareProject(2)">确 定</el-button>
            <el-button @click="shareProject(1)">取 消</el-button>
          </span>
        </el-dialog>
      </div>
      <div class="project flex space-between">
        <div class="left flex">
          <h1 class="flex align-center">
            项目选择：<span style="color: #c4c4c4; font-size: 16px; font-weight: normal"
              >(从已完成的项目库中选择)</span
            >
          </h1>
          <div class="flex align-center mg-t-20 mg-b-20">
            <span>标签筛选：</span>
            <el-select
              v-model="value"
              placeholder="请选择"
              @change="tagSelect"
              clearable
              @clear="tagClear"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="tablebox">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              :row-key="
                (row) => {
                  return row.id;
                }
              "
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                label="选择框"
                :reserve-selection="true"
              >
              </el-table-column>
              <el-table-column label="项目名称">
                <template slot-scope="scope">{{ scope.row.title }}</template>
              </el-table-column>
              <el-table-column prop="tagName" label="项目标签"> </el-table-column>
              <el-table-column prop="gmtCreated" label="项目创建时间"> </el-table-column>
            </el-table>
          </div>
          <!-- <p style="text-align: right">
            <el-pagination :total="5" layout="prev, pager, next"> </el-pagination>
          </p> -->
        </div>
        <div class="right flex">
          <h1>项目集合</h1>
          <div class="projectBox">
            <div v-if="selectProject.length > 0" style="padding: 20px">
              <div class="item" v-for="(item, index) in selectProject" :key="item.id">
                <h1 class="flex align-center">
                  <span>{{ index + 1 }}、</span
                  ><span class="title">{{ item.title }}</span>
                </h1>
                <div class="childItem" v-for="jtem in item.projectSubDetailList">
                  <h1>{{ jtem.title }}</h1>
                </div>
              </div>
            </div>
            <p v-else style="padding: 20px; text-align: center; color: #c5c5c5">
              请勾选左边的项目
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      list: [],
      options: [],
      tagId: "",
      tableData: [],
      value: "",
      selectProject: [],
      dialogVisible: false,
      title: "",
      tag: "",
      desc: "",
    };
  },
  created() {
    this.getListProject();
    this.getAllTags();
    if (this.$route.query.id) {
      this.title = this.$route.query.title;
      this.tag = this.$route.query.tag;
      this.desc = this.$route.query.remark;
    }
  },
  methods: {
    getInfo() {
      this.$post(`/project-share/draw`, {
        id: this.$route.query.id,
      }).then((res) => {});
    },
    // 勾选
    handleSelectionChange(row) {
      console.log(row);
      this.selectProject = row;
    },
    // 查询子项目
    getChildItem(parentId) {
      this.$get(`/project_sub/list-by-parent-id?parentProjectId=${parentId}`).then(
        (res) => {
          this.childList = res.data.data;
        }
      );
    },
    //分享项目
    shareProject(n) {
      let mainProjectIdList = this.selectProject.map((item) => item.id);
      if (!this.$route.query.id) {
        this.$post(`/project-share/add`, {
          title: this.title,
          tag: this.tag,
          desc: this.desc,
          operateType: n,
          mainProjectIdList,
        }).then((res) => {
          if (res.data.data == true) {
            this.dialogVisible = false;
            this.$router.push("/school/myShareList");
          }
        });
      } else {
        this.$post(`/project-share/edit`, {
          id: this.$route.query.id,
          title: this.title,
          tag: this.tag,
          desc: this.desc,
          operateType: n,
          mainProjectIdList,
        }).then((res) => {
          if (res.data.data == true) {
            this.dialogVisible = false;
            this.$router.push("/school/myShareList");
          }
        });
      }
    },
    // 标签筛选
    tagSelect(id) {
      console.log(id);
      this.tagId = id;
      this.getListProject();
    },
    tagClear() {
      this.tagId = "-1";
      this.getListProject();
    },
    getListProject() {
      this.tableData = [];
      this.$get(`/wd/listProject?status=3&tagId=${this.tagId} `).then((res) => {
        this.tableData = res.data.data;
        this.tableData.map((item) => {
          item.tagName = "";
          if (
            item.projectTagRelationList != null &&
            item.projectTagRelationList.length > 0
          ) {
            item.projectTagRelationList.map((jtem) => {
              item.tagName += `#${jtem.tagName}`;
            });
          }
        });
        if (this.$route.query.id) {
          // this.tableData.map((item) => {
          //   this.$route.query.mainProjectIds.map((jtem) => {
          //     if (item.id == jtem) {
          //       this.selectProject.push(item);
          //     }
          //   });
          // });
          this.tableData.forEach((row) => {
            if (this.$route.query.mainProjectIds.includes(row.id)) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(row, true);
              });
            }
          });
        }
      });
    },
    // 所有标签
    getAllTags() {
      this.$get(`/tag/listAllTag`).then((res) => {
        this.options = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  background: #fff;
  height: 100vh;
  padding-left: 70px;
  .content {
    padding: 20px;
  }
}
.project {
  h1 {
    font-size: 28px;
  }
  .left {
    width: 58%;
    flex-direction: column;
    height: 58vh;
    .tablebox {
      flex: 1;
      overflow: auto;
    }
  }
  .right {
    width: 40%;
    flex-direction: column;
    h1 {
      margin-bottom: 20px;
    }
    .projectBox {
      flex: 1;
      max-height: 50vh;
      border: 1px solid #ccc;
      overflow-y: auto;
      .item {
        padding: 5px;
        h1 span {
          font-size: 30px;
        }
        .title {
          width: 88%;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          vertical-align: middle;
        }
        .childItem {
          h1 {
            padding-left: 60px;
            font-size: 22px;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:before {
              content: "";
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 15px solid black;
              position: absolute;
              left: 35px;
              top: 9px;
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }
}
.info {
  height: 100%;
  .nav {
    .left {
      cursor: pointer;
    }
    .arrow {
      width: 10px;
      height: 20px;
      font-size: 18px;
    }
  }
  .el-input,
  .el-textarea {
    width: calc(100% - 70px);
  }
}
::v-deep .mainTitle .el-input__inner {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #aaa;
}
::v-deep .node .el-input__inner {
  border-radius: 0;
  border: none;
  background: #f7f7f8;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  background: none;
}
.el-tag {
  width: 30%;
  padding: 10px 0;
  height: auto;
  font-size: 16px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: none;
}
.el-tag + .el-tag {
  margin-left: 20px;
}
.el-tag:nth-child(3n + 1) {
  margin-left: 0;
}
.el-tag.el-tag--info {
  color: #000;
  position: relative;
  word-break: break-all;
  white-space: break-spaces;
  padding: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-tag .el-icon-close {
  top: -5px;
  right: -5px;
  position: absolute;
  width: 17px;
  height: 17px;
  line-height: 17px;
}
::v-deep .add.el-button--primary {
  background-color: #000 !important;
  border-color: #000 !important;
  padding: 5px 12px;
}
::v-deep .el-tag .el-icon-close::before {
  background: red;
  border-radius: 50%;
  color: #fff;
}
::v-deep .el-table th.el-table__cell {
  background: #41c68e;
  color: #fff;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #41c68e;
  border-color: #41c68e;
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #41c68e;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner,
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #41c68e;
  border-color: #41c68e;
}
::v-deep .el-button.el-button--primary {
  background: #41c68e;
  border-color: #41c68e;
}

::v-deep .el-textarea__inner:focus {
  border-color: #41c68e;
}
</style>
