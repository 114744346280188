<template>
  <el-dialog
    title="邮箱登录/注册"
    :visible.sync="registerVisible"
    width="40%"
    :center="true"
    @close="closeVisible"
    :close-on-click-modal="false"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      class="demo-ruleForm"
    >
      <el-form-item label="邮箱" prop="emailBox">
        <div class="flex">
          <el-input v-model="form.emailBox"></el-input>
          <el-button
            class="mg-l-10"
            type="primary"
            :icon="isLoading ? 'el-icon-loading' : ''"
            :disabled="countdown > 0"
            @click="sendEmailCode('ruleForm')"
            >{{ tips }}</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="验证码" prop="code">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
      <!-- <el-form-item label="密码" prop="password">
        <el-input v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input v-model="form.confirmPassword"></el-input>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <div>
        <el-button @click="closeVisible">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
      <!-- <div class="flex space-center mg-t-20">
        <el-button class="mg-r-20" type="text">密码登录</el-button>
        <el-button type="text">邮箱登录</el-button>
      </div> -->
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    registerVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        theme: "LOGIN",
        loginType: 2,
        emailBox: "",
        code: "",
        sendEmailToken: "",
      },
      tips: "获取验证码",
      countdown: 0,
      timer: null,
      isLoading: false,
      rules: {
        emailBox: { required: true, message: "请输入邮箱", trigger: "blur" },
        code: { required: true, message: "请输入验证码", trigger: "blur" },
      },
    };
  },
  created() {},
  methods: {
    closeVisible() {
      for (let i in this.form) {
        this.form[i] = "";
      }
      this.form.theme = "LOGIN";
      this.form.loginType = 2;
      this.$emit("updateVisible", false);
    },
    sendEmailCode() {
      const { theme, emailBox } = this.form;
      let reg = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (emailBox === "") {
        this.$message.error(new Error("请输入邮箱地址"));
        return false;
      } else if (!reg.test(emailBox)) {
        this.$message.error("请输入正确的邮箱格式");
        return false;
      }
      this.isLoading = true;

      this.$post("/email/sendEmail", {
        theme,
        emailBox,
      }).then((res) => {
        this.isLoading = false;
        if (res.data.success) {
          this.form.sendEmailToken = res.data.data;
          this.$message.success("获取成功!");
          localStorage.setItem("sendEmailToken", this.form.sendEmailToken);
          // 倒计时
          if (this.countdown > 0) {
            return;
          }
          const countdownDuration = 60;
          this.countdown = countdownDuration;
          this.tips = this.countdown + "s后重新获取";
          // 创建定时器，每秒减少倒计时
          this.timer = setInterval(() => {
            this.countdown--;
            this.tips = this.countdown + "s后重新获取";
            // 如果倒计时结束，清除定时器
            if (this.countdown <= 0) {
              this.countdown = 0;
              this.tips = "获取验证码";
              this.countdown = 0;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { code, emailBox, sendEmailToken, loginType } = this.form;
          this.$post("/auth/token", {
            loginType,
            emailCheckCodeLoginRequest: {
              code,
              emailBox,
              sendEmailToken: sendEmailToken || localStorage.getItem("__sendEmailToken"),
            },
          }).then((res) => {
            if (res.data.success) {
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem("userId", res.data.data.userId);
              this.$message.success("登录成功!");
              window.location.reload();
              this.$router.push("/project/page-list/projectList");
              this.$emit("updateVisible", false);
            } else {
              this.$message.error(res.data.errorMessage);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.el-form .el-button {
  margin-left: 10px !important;
}
.el-form .el-form-item:last-child {
  margin-bottom: 0 !important;
}

::v-deep .el-dialog__footer {
  padding-bottom: 30px;
}
::v-deep .el-button--primary.is-disabled {
  background-color: #666 !important;
  border-color: #666 !important;
}
</style>
