<template>
  <div class="main flex space-between">
    <div class="profile">
      <div class="top flex align-center" v-if="Object.keys(userInfo).length > 0">
        <div class="line flex align-center">
          <el-image
            class="star"
            :src="require('@/static/images/card.png')"
            lazy
          ></el-image>
          <p class="mg-l-10" style="margin-right: 50px">
            <span>信用点：</span
            ><b style="color: #ffb800">{{ userInfo.memberCapitalDO.creditScore }}</b>
          </p>
        </div>
        <div class="line flex align-center">
          <el-image
            class="star"
            :src="require('@/static/images/circle.png')"
            lazy
          ></el-image>
          <p class="mg-l-10">
            <span>火种：</span><b>{{ userInfo.memberCapitalDO.fireScore }}</b>
          </p>
        </div>
        <!-- <el-button type="primary" @click="seedVisible = true">获取</el-button> -->
      </div>
      <div class="bottom mg-t-20">
        <div class="tab">
          <ul>
            <li
              v-for="(item, index) in tabs"
              :key="index"
              @click="changeTab(item)"
              :class="{ on: current == item }"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="list">
          <div class="listBox" v-if="listSeed.length > 0">
            <div
              class="item flex space-between align-center"
              :class="{ current: shopIndex == index }"
              v-for="(item, index) in listSeed"
              :key="index"
              @click="choose(index, item, 1)"
            >
              <div class="img flex align-center">
                <el-image
                  class="flower"
                  :src="item.materialDO.img"
                  :alt="item.materialDO.blurb"
                  lazy
                ></el-image>
                <p class="text-center">{{ item.targetName }}</p>
              </div>
              <img class="arrow-right" src="../../static/images/arrow-right.png" alt="" />
            </div>
          </div>
          <div class="listBox" v-else>
            <p class="mg-t-20 color-999">暂无</p>
          </div>
          <pagination
            :total="tableData.total"
            :per_page="tableData.per_page"
            :current_page="tableData.current_page"
            @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"
          />
        </div>
      </div>
    </div>
    <div class="content">
      <ShopInfo :detail="detail" :type="type" @updateMember="getMemberInfo" />
    </div>
    <el-dialog title="" :visible.sync="seedVisible" width="40%" center>
      <div class="flex align-center">
        <span class="label"></span>
        <el-image class="ewm" :src="require('@/static/images/ewm.png')" lazy></el-image>
        <p class="mg-l-20 font-24">微信扫描该二维码</p>
      </div>
      <div class="flex align-center mg-t-20">
        <span class="label font-24">兑换码：</span>
        <el-input
          type="text"
          maxlength="10"
          show-word-limit
          placeholder="请输入最新名称"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="seedVisible = false">确 定</el-button>
        <el-button @click="seedVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ShopInfo from "./page-list/shopInfo.vue";
import pagination from "@/components/pagination";
export default {
  name: "",
  components: {
    ShopInfo,
    pagination,
  },
  data() {
    return {
      detail: {},
      seedVisible: false,
      tabs: ["种子", "材料", "皮肤"],
      current: "种子",
      shopIndex: 0,
      shopIndex1: 0,
      shopIndex2: 0,
      id: "",
      type: 1,
      tableData: {},
      listSeed: [],
      listSkin: [],
      listMaterial: [],
      userInfo: {},
    };
  },

  created() {
    this.getList();
    this.getMemberInfo();
  },
  methods: {
    // 获取我的信息
    getMemberInfo() {
      this.$get("/my/getMemberInfo").then((res) => {
        this.userInfo = res.data.data;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
      });
    },
    // 获取种子列表
    getList() {
      let type = 0;
      if (this.current == "种子") {
        type = 2;
      } else if (this.current == "材料") {
        type = 1;
      } else {
        type = 3;
      }
      this.$post("/commodity/pageList", {
        type: type,
        size: this.tableData.per_page,
        pageIndex: this.tableData.current_page,
      }).then((res) => {
        this.listSeed = res.data.data.records;
        this.listSeed.map((item) => {
          if (item.seedDO != null) {
            // this.$set(item.materialDO, item.seedDO);
            item.materialDO = item.seedDO;
          } else if (item.skinDO != null) {
            item.materialDO = item.skinDO;
          }
        });

        console.log(this.listSeed);
        this.tableData.total = res.data.total;
        if (this.listSeed.length > 0) {
          this.detail = this.listSeed[0];
          console.log(this.detail);
        } else {
          this.detail = {};
        }
      });
    },

    choose(i, item, type) {
      this.shopIndex = i;
      this.detail = item;
      this.type = type;
    },
    choose1(i, item, type) {
      this.shopIndex1 = i;
      this.detail = item;
      this.type = type;
    },
    choose2(i, item, type) {
      this.shopIndex2 = i;
      this.detail = item;
      this.type = type;
    },
    loginOut() {
      this.quitVisible = true;
    },
    changeTab(i) {
      console.log(i);
      this.current = i;
      this.getList();
    },
    updateVisible() {
      this.setVisible = false;
    },
    handleSizeChange(v) {
      this.tableData.per_page = Number(v);
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = Number(v);
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding-left: 70px;
  height: 100vh;
  .ewm {
    width: 10vw;
    height: 10vw;
  }
  .label {
    width: 120px;
    display: inline-block;
  }
  .el-input {
    width: 400px;
  }
  .profile {
    min-width: 420px;
    padding: 30px 28px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 32%;
    height: 100%;
    background: #fff;
    .top {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
    }
    .bottom {
      height: calc(100% - 42px);
      ul {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        li {
          display: inline-block;
          padding-right: 50px;
          cursor: pointer;
          font-size: 20px;
          &.on {
            color: #41c68e;
          }
        }
      }
      .list {
        width: 100%;
        height: calc(100% - 10px);
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: visible;
        padding: 0 10px;
        .item {
          padding: 10px 0;
          padding-left: 10px;
          margin-top: 20px;
          cursor: pointer;
          background: #f7f8f8;
          border-radius: 10px;
          // box-shadow: 0px 0 4px 0px rgba(0, 0, 0, 0.25);
          p {
            font-size: 18px;
          }
          .arrow-right {
            width: 12px;
            height: 22px;
            margin-right: 20px;
          }
        }

        .item.current {
          color: #41c68e;
          p {
            font-size: 18px;
            font-weight: bold;
          }
        }
        .img {
          align-items: center;
          border-radius: 10px;
        }
        .flower {
          width: 50px;
          height: 50px;
          background: #fff;
          margin-right: 10px;
        }

        &.show {
          display: block;
        }
      }
    }
  }
  .content {
    padding: 25px 38px;
    box-sizing: border-box;
    height: 100%;
    width: 68%;
    border-left: 1px solid #888;
    min-width: 730px;
    background: #fff;
    overflow: hidden;
  }
}
::v-deep .el-button--primary {
  background-color: #41c68e !important;
  border-color: #41c68e !important;
  padding: 10px 20px;
}
</style>
