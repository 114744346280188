<template>
  <div class="main">
    <div class="content flex space-between">
      <div class="left">
        <h1 class="status" style="text-align: left">
          <span :class="{ on: status == 2 }" @click="getProjectList(2)">热门项目</span>
          <span
            style="display: inline-block; margin-left: 20px"
            @click="getProjectList(1)"
            :class="{ on: status == 1 }"
            >最新项目</span
          >
        </h1>
      </div>
      <div
        class="right flex"
        style="text-align: right; justify-content: center; cursor: pointer"
        @click="$router.push('/school/myShareList')"
      >
        <img src="../../static/images/avatar_1.png" style="width: 40px; height: 40px" />
        <h1 style="margin: 0 10px; font-size: 24px">我的</h1>
        <img
          src="../../static/images/arrow-right.png"
          style="width: 15px; height: 25px; margin-top: 6px"
        />
      </div>
    </div>
    <div class="content">
      <div class="itembox flex flex-wrap" v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="item.id" @click="toInfo(item)">
          <div class="img" :style="{ backgroundImage: `url(${item.img})` }">
            <div class="layout"></div>
            <h1>{{ item.title }}</h1>
            <div class="bottom flex align-center space-between">
              <div class="tag">#{{ item.tag }}</div>
              <p class="toEdit">详情</p>
            </div>
          </div>
          <div class="text">
            <h3><span>简介：</span>{{ item.remark }}</h3>
            <div class="flex bot space-between align-center">
              <p>
                <span class="logo">DAO</span>
                <i style="font-size: 16px; display: inline-block; margin-left: 5px">{{
                  item.memberName
                }}</i>
              </p>
              <p
                class="flex align-center like"
                @click.stop="changeLiked(item)"
                style="cursor: pointer"
              >
                <el-image
                  style="width: 20px"
                  v-if="!item.isLiked"
                  :src="require('../../static/images/unlike.png')"
                ></el-image>
                <el-image
                  v-else
                  style="width: 20px"
                  :src="require('../../static/images/like.png')"
                ></el-image>
                <span style="font-size: 16px; display: inline-block; margin-left: 5px">{{
                  item.likeNum
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "school",
  data() {
    return {
      list: [],
      status: 2,
    };
  },
  created() {
    this.getProjectList(2);
  },
  methods: {
    toInfo(row) {
      // row = JSON.stringify(row);
      row.infoType = "list";
      this.$router.push({
        path: "/school/info",
        query: { ...row },
      });
    },
    changeLiked(item) {
      if (item.isLiked) {
        this.$post(`/project-share/cancel-like`, {
          id: item.id,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("取消点赞成功！");
            this.getProjectList(this.status);
          }
        });
      } else {
        this.$post(`/project-share/add-like`, {
          id: item.id,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("点赞成功！");
            this.getProjectList(this.status);
          }
        });
      }
    },
    getProjectList(n) {
      this.status = n;
      this.$post(`/project-share/page-project-shares`, {
        size: 6,
        page: 1,
        status: 3,
        orderByField: n,
      }).then((res) => {
        res.data.data.records.map((item) => {
          item.img = item.img.replace(
            "http://www.growthx-verse.com",
            "http://47.76.52.13:9000"
          );
        });
        this.list = res.data.data.records;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 100vh;
  padding-left: 70px;
  .toEdit {
    text-align: center;
    width: 100px;
    height: 30px;
    line-height: 30px;
    background: #41c68e;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    z-index: 2;
  }
  .itembox {
    margin-bottom: 20px;
    > div + div {
      margin-left: 2%;
    }
    > div:nth-child(4n + 1) {
      margin-left: 0;
    }
    > div {
      width: 23%;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      .text {
        padding: 20px;
        background: #fff;
        h3 {
          color: #999;
          font-weight: normal;
          height: 60px;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 定义显示的最大行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            color: #000;
          }
          margin-bottom: 15px;
        }
        .bot {
          span.logo {
            color: #000;
            display: inline-block;
            font-weight: bold;
            padding: 0 5px;
            border: 1px solid #000;
            border-radius: 4px;
          }
          i {
            font-style: normal;
          }
        }
      }
      .img {
        width: 100%;
        height: 20vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        padding: 20px;
        box-sizing: border-box;
        .bottom {
          position: absolute;
          left: 20px;
          right: 20px;
          bottom: 20px;
          color: #fff;
          a {
            color: #fff;
            padding: 5px 10px;
            background: #41c68e;
            border-radius: 4px;
            line-height: 16px;
          }
        }
        .layout {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 0;
          left: 0;
        }
        h1 {
          color: #fff;
          position: relative;
          z-index: 2;
          font-size: 26px;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 定义显示的最大行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .content {
    padding: 20px;
    h1 span {
      font-size: 28px;
    }
    .left {
      width: 80%;
      .status {
        span {
          color: #999;
          cursor: pointer;
          font-weight: normal;
        }
        .on {
          font-weight: bold;
          color: #000;
        }
      }
    }
  }
}
</style>
