<template>
  <div class="main flex space-between">
    <div class="profile">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="(list, index) in items"
            :key="index"
            class="swiper-slide swiper-no-swiping"
          >
            <p class="text-center name">{{ list.seedDO.name }}</p>
            <p class="material" :class="{ ani: list.currentGenieScore == 300 }">
              掉落材料 +1
            </p>
            <el-image class="skin" :src="list.currentStageImg"></el-image>
            <!--  每个滑块的内容 -->
            <div class="progress-container">
              <div class="progress-bar">
                <div class="currentName">{{ list.currentStageName }}</div>
                <div class="current" :style="{ left: 0 + '%' }">
                  {{ list.currentGenieScore }}
                </div>
                <div
                  class="progress-fill"
                  :style="{ width: list.currentLength + '%' }"
                ></div>
                <div class="fill">{{ list.currentStageEndScore }}</div>
                <div class="fillName">{{ list.nextStageName }}</div>

                <div
                  class="fire"
                  @click="addFire(list.plantId)"
                  v-if="list.currentGenieScore < 500"
                >
                  <img
                    class="star swiper-lazy"
                    src="../../static/images/fire2.png"
                    lazy
                  />
                  <p class="usable">
                    剩余: {{ list.validGenieScore }}
                    <span class="less" :class="{ ani: isAni }">-5</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="sing">
              {{ list.seedDO.songLan }}
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <div class="content">
      <FlowerInfo :detail="detail" :type="type" @updateMember="getLingLanZhiJingPlant" />
    </div>
  </div>
</template>
<script>
import FlowerInfo from "./page-list/flowerInfo.vue";
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  name: "",
  components: {
    FlowerInfo,
  },
  data() {
    return {
      detail: {},
      tabs: ["种子", "材料", "皮肤"],
      current: 0,
      shopIndex: 0,
      shopIndex1: 0,
      shopIndex2: 0,
      isAni: false,
      id: "",
      type: 1,
      listSeed: [],
      listSkin: [],
      listMaterial: [],
      userInfo: {},
      items: [],
    };
  },

  created() {
    this.getLingLanZhiJingPlant();
  },
  mounted() {},
  methods: {
    addFire(id) {
      this.$post("/llzj/addLingForPlant", {
        plantId: id,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("成功 + 5 灵！");
          this.getLingLanZhiJingPlant();
          this.isAni = true;
          let i = setTimeout(() => {
            this.isAni = false;
          }, 500);
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    // 获取植物信息
    getLingLanZhiJingPlant() {
      this.$get("/llzj/lingLanZhiJingPlants").then((res) => {
        let that = this;
        this.items = res.data.data;
        this.detail = this.items[0];
        this.items.map((item) => {
          item.currentLength =
            (
              (item.currentGenieScore - item.currentStageStartScore) /
              (item.currentStageEndScore - item.currentStageStartScore)
            ).toFixed(2) * 100;
        });
        this.$nextTick(() => {
          console.log(2);
          new Swiper(".profile .swiper-container", {
            slidesPerView: 1,
            lazy: {
              loadPrevNext: true,
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            on: {
              // 这个是获取当前的滑块的索引
              slideChange: function () {
                console.log(this.activeIndex);
                that.detail = that.items[this.activeIndex];
                that.plantId = that.items[this.activeIndex].plantId;
              },
            },
          });
        });
        console.log(1);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 100vh;
  padding-left: 70px;

  .ewm {
    width: 10vw;
    height: 10vw;
  }
  .label {
    width: 120px;
    display: inline-block;
  }
  .el-input {
    width: 400px;
  }
  .profile {
    min-width: 420px;
    padding: 30px 38px;
    box-sizing: border-box;
    width: 32%;
    height: 100%;
    background: #fff;
    .skin {
      width: 75%;
    }
    .swiper-slide {
      text-align: center;
      position: relative;
      .material {
        z-index: 2;
        color: #41c68e;
        position: absolute;
        top: 20%;
        right: 10%;
        opacity: 0;
      }
      .material.ani {
        animation: toTop2 2s;
      }
      padding-top: 10vh;
      .name {
        font-size: 24px;
        font-weight: bold;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
      }
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      color: #000;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 18px;
    }
    .swiper-button-next {
      right: 19%;
    }
    .swiper-button-prev {
      left: 19%;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 24px;
    }
    .top {
      width: 100%;
    }
    .bottom {
      ul {
        width: 100%;
        li {
          display: inline-block;
          padding-right: 50px;
          cursor: pointer;
          &.on {
            color: #41c68e;
          }
        }
      }
      .list {
        width: 100%;
        .item {
          width: 30%;
          padding: 20px 0;
          margin-top: 20px;
          cursor: pointer;
        }
        .item.current {
          .img {
            background: rgba(112, 234, 54, 0.34);
          }
          color: #41c68e;
        }
        .img {
          background: #f7f8f8;
          padding: 22px 0;
          border-radius: 10px;
        }
        .flower {
          width: 113px;
          height: 113px;
        }
        .item + .item {
          margin-left: 2%;
        }
        .item:nth-child(3n + 1) {
          margin-left: 0;
        }
        &.show {
          display: block;
        }
      }
    }
  }
  .content {
    padding: 25px 38px;
    box-sizing: border-box;
    height: 100%;
    width: 68%;
    min-width: 730px;
    border-left: 1px solid #888;
    background: #fff;
    overflow: hidden;
  }
}
::v-deep .el-button--primary {
  background-color: #41c68e !important;
  border-color: #41c68e !important;
  padding: 10px 20px;
}

.progress-container {
  display: flex;
}

.progress-bar {
  width: 60%;
  // margin: 0 auto;
  margin-left: 14%;
  height: 10px;
  background-color: #ddd;
  border-radius: 20px;
  position: relative;
  margin-top: 40px;
}

.progress-bar .current {
  position: absolute;
  top: -220%;
}
.currentName {
  position: absolute;
  bottom: -220%;
  left: 0;
  font-size: 12px;
}
.fillName {
  position: absolute;
  bottom: -220%;
  right: 0;
  font-size: 12px;
}
.progress-bar .fill {
  position: absolute;
  top: -220%;
  right: 0;
}
.sing {
  color: #999;
  margin-top: 40px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}
.fire {
  width: 100px;
  position: absolute;
  left: 95%;
  top: -50%;
  margin-top: -13px;
  cursor: pointer;
}
.fire .less {
  position: absolute;
  bottom: 100%;
  left: 50%;
  font-size: 14px;
  color: red;
  transform: translateX(-50%);
  opacity: 0;
}
.fire .less.ani {
  animation: toTop 1.5s;
}
@keyframes toTop {
  0% {
    bottom: 100%;
    opacity: 1;
  }
  100% {
    bottom: 120%;
    opacity: 0;
  }
}
@keyframes toTop2 {
  0% {
    top: 30%;
    opacity: 1;
  }
  100% {
    top: 20%;
    opacity: 0;
  }
}
.fire p {
  font-size: 10px;
  color: #999;
}
.star {
  width: 30px;
  height: 30px;
}
.progress-fill {
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(68deg, #5ade39 45.03%, #41c68e 82.13%);
  transition: width 0.5s ease;
  position: absolute;
  width: 0;
}
</style>
