<template>
  <div class="text-center mg-t-20" v-if="total">
    <el-pagination
      :small="small"
      :pager-count="pagerCount"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="current_page"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="Number(per_page)"
      :layout="layout"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    current_page: {
      type: [Number , String],
      default: 1
    },
    per_page: {
      type: [Number , String],
      default: 10
    },
    total: {
      type: [Number , String],
      default: 0
    },
    small: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    pagerCount: {
      type: [Number , String],
      default: 11
    }
  },
  data () {
    return {}
  },
  created () {
    console.log(this.total)
  },
  methods: {
    handleSizeChange (v) {
      this.$emit('handleSizeChange', Number(v))
    },
    handleCurrentChange (v) {
      this.$emit('handleCurrentChange', Number(v))
    }
  }
}
</script>
