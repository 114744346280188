<template>
  <div class="info">
    <div class="btn text-right">
      <el-button type="info" size="mini" @click="seedVisible = true">放弃</el-button>
    </div>
    <div class="nav" v-if="Object.keys(info).length > 0">
      <h1 class="text-center" style="font-size: 24px">
        {{ info.seedDO.name }}
      </h1>
    </div>
    
    <div class="infomation" v-if="Object.keys(info).length > 0">
      <!-- <div class="swip">
        <p class="mg-b-20">
          <span class="label">变种：</span>
        </p>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(list, index) in items"
              :key="index"
              class="swiper-slide"
            >
              <el-image class="skin" :src="list.src"></el-image>
              <p class="text-center">{{ list.name }}</p>
            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div> -->
      <div class="text">
        <div class="flex">
          <div>
            <p class="label">名称：</p
            ><b style="font-size: 17px">{{ info.seedDO.name }}</b>
          </div>
          <div style="margin-left: 100px">
            <p class="label">属性：</p
            ><b style="font-size: 17px">{{ info.seedDO.categoryName }}</b>
          </div>
          <div style="margin-left: 100px">
            <p class="label">价格：</p
            ><b style="font-size: 17px; color: #ffb800"
              >{{ info.seedDO.price }} 信用点</b
            >
          </div>
        </div>
        <div>
          <div class="flex">
            <p class="label">简介：</p>
            <p class="pro" style="font-size: 17px">
              {{ info.seedDO.blurb }}
            </p>
          </div>
        </div>
        <div class="flex">
            <p class="label">歌语：</p>
            <p class="pro">
              <b class="pro" style="color: #41c68e;font-size: 16px;" v-html="info.seedDO.songLan"></b>
            </p>
        </div>
        <div class="flex">
          <div class="flex align-center">
            <p class="label">掉落材料：</p>
            <b style="font-size: 17px">{{ info.seedDO.materialName || '暂无' }}</b>
          </div>
          <div class="flex align-center" style="margin-left: 100px">
            <p class="label" style="width: 120px;">种子售卖价值：</p>
            <p>
              <b style="font-size: 17px"
                >{{ info.seedDO.saleCreditPrice }} 信用点</b
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty description="请前往市井购买种子"></el-empty>
    </div>
    <el-dialog title="" :visible.sync="sendVisible" width="40%" center>
      <p style="font-size: 24px; text-align: center">
        确定以<span style="color: #ffb800; font-size: 20px"
          >{{ info.price }}信用点</span
        >购买该{{ type == 1 ? "种子" : type == 2 ? "材料" : "皮肤" }}？
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBuy">确 定</el-button>
        <el-button @click="sendVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="" :visible.sync="seedVisible" width="40%" center>
      <p style="font-size: 24px; text-align: center">确定放弃该植物的种植？</p>
      <p style="font-size: 14px;text-align: center;color: red;margin-top: 10px">丢弃后，该植物无法找回</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="delTree">确 定</el-button>
        <el-button @click="seedVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  name: "seedInfo",
  props: {
    detail: {
      type: Object,
      default: {},
    },
    type: {
      type: Number,
      default: "1",
    },
  },
  data() {
    return {
      sendVisible: false,
      seedVisible: false,
      info: {},
      items: [
        {
          name: "香槟夜兰",
          src: require("@/static/images/skin1.png"),
        },
        {
          name: "香槟夜兰",
          src: require("@/static/images/skin2.png"),
        },
        {
          name: "香槟夜兰",
          src: require("@/static/images/skin1.png"),
        },
        {
          name: "香槟夜兰",
          src: require("@/static/images/skin2.png"),
        },
      ],
    };
  },
  created() {
    // let i = setTimeout(() => {
    //   new Swiper(".swip .swiper-container", {
    //     slidesPerView: 3,
    //     spaceBetween: 20,
    //     navigation: {
    //       nextEl: ".swiper-button-next",
    //       prevEl: ".swiper-button-prev",
    //     },
    //     on: {
    //       // 这个是获取当前的滑块的索引
    //       slideChange: function () {
    //         //  that.active = this.activeIndex
    //       },
    //     },
    //   });
    //   clearTimeout(i);
    // }, 1000);
  },
  watch: {
    detail(n, o) {
      console.log(n)
      this.info = {};
      this.info = n;
    },
  },
  methods: {
  
    confirmBuy() {
      this.$post("/sj/purchaseCommodity", {
        commodityType: this.type,
        commodityId: this.info.plantId,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("购买成功！");
          this.sendVisible = false;
          this.$emit("updateMember");
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    changeTab(i) {
      this.current = i;
    },
    delTree () {
      console.log(this.info)
      this.$post("/llzj/discardPlant", {
        id: this.detail.plantId,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("操作成功！");
          this.sendVisible = false;
          this.$emit("updateMember");
          location.reload()
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.info {
  height: 100%;
  .nav {
    .arrow {
      width: 10px;
      height: 20px;
      font-size: 18px;
    }
    .left {
      cursor: pointer;
      p {
        font-weight: bold;
      }
    }
    .sold {
      background: none !important;
      color: #41c68e;
    }
  }
  .flower {
    width: 300px;
    height: 300px;
  }
  .infomation {
    height: calc(100% - 36px);
    overflow-y: auto;
    .text {
      margin-top: 20px;
    }
    div {
      margin-top: 20px;

      p.label {
        display: inline-block;
        width: 100px;
        color: #999;
      }
      .pro {
        line-height: 26px;
        color: #666;
        flex: 1;
      }
    }
  }
}
::v-deep .el-button--primary {
  background-color: #41c68e !important;
  border-color: #41c68e !important;
  padding: 10px 20px;
}
.el-button + .el-button {
  margin-left: 20px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff;
}
.swiper-button-next,
.swiper-button-prev {
  top: 40%;
}
</style>
