<template>
  <div class="main flex">
    <div class="profiles">
      <div class="flex align-center space-between" v-if="$route.query.infoType">
        <div class="left flex align-center" @click="$router.push('/school')">
          <el-image
            class="arrow"
            :src="require('@/static/images/left-arrow.png')"
            lazy
          ></el-image>
          <p class="mg-l-20">返回</p>
        </div>
        <div class="getProject" @click="dialogVisible = true">收藏项目</div>
      </div>
      <div class="flex align-center space-between" v-else>
        <div class="left flex align-center" @click="$router.push('/school/myShareList')">
          <el-image
            class="arrow"
            :src="require('@/static/images/left-arrow.png')"
            lazy
          ></el-image>
          <p class="mg-l-20">返回</p>
        </div>
      </div>
      <div class="menuInfo" v-if="Object.keys(form).length > 0">
        <div class="img" :style="{ backgroundImage: `url(${form.img})` }">
          <div class="layout"></div>
          <h1>{{ form.title }}</h1>
        </div>
        <div class="text">
          <div class="flex bot space-between align-center">
            <p>
              <span class="logo">DAO</span>
              <i style="font-size: 16px; display: inline-block; margin-left: 5px">{{
                form.memberName
              }}</i>
            </p>
            <p class="flex align-center" @click="changeLiked(form)">
              <el-image
                style="width: 20px"
                v-if="!form.isLiked"
                :src="require('../../static/images/unlike.png')"
              ></el-image>
              <el-image
                v-else
                style="width: 20px"
                :src="require('../../static/images/like.png')"
              ></el-image>
              <span style="font-size: 16px; display: inline-block; margin-left: 5px">{{
                form.likeNum
              }}</span>
            </p>
          </div>
          <h3><span>简介：</span>{{ form.remark }}</h3>
        </div>
        <div class="tag">
          标签：<el-tag type="primary">{{ form.tag }}</el-tag>
        </div>
        <div class="projectBox">
          <div v-if="mainProject.length > 0">
            <div class="item" v-for="(item, index) in mainProject" :key="item.id">
              <h1
                :class="{ on: currentLevel1Index == item.id && currentLevel2Index == '' }"
                @click="choose(item.id, 1, 'parent')"
                class="flex align-center"
              >
                <span>{{ index + 1 }}、</span><span class="title">{{ item.title }}</span>
              </h1>
              <div class="childItem" v-for="jtem in item.projectSubDetailList">
                <h1
                  :class="{
                    on: currentLevel1Index == '' && currentLevel2Index == jtem.id,
                  }"
                  @click="choose(jtem.id, 2, 'child')"
                >
                  {{ jtem.title }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="infomation" v-if="Object.keys(info).length > 0">
        <h2
          class="mg-b-20"
          style="font-size: 28px; font-weight: bold; color: #41c68e; letter-spacing: 4px"
        >
          {{ info.title }}
        </h2>
        <p style="color: #bcbcbc">
          {{ info.gmtCreated }}
          <span class="childTag" v-if="type != 'parent'">子项目</span>
        </p>
        <el-tabs v-model="activeName">
          <el-tab-pane label="总览" name="first">
            <span slot="label">
              <img
                class="first"
                v-if="activeName == 'first'"
                src="../../static/images/icon_6_1.png"
                alt=""
              />
              <img class="first" v-else src="../../static/images/icon_6.png" alt="" />

              总览
            </span>
            <h4 class="mg-t-30" style="font-size: 20px">计划</h4>
            <div class="flex mg-t-20 flex-wrap">
              <el-tag
                class="text-center mg-b-20"
                v-for="item in info.nodeList"
                type="info"
                :key="item.id"
                >{{ item.name }}</el-tag
              >
            </div>
            <h4 class="mg-t-30" style="font-size: 20px">技能</h4>

            <div class="mg-t-20 skillItemBox">
              <div
                class="box flex"
                v-if="info.skillList.length > 0"
                :style="{ width: info.skillList.length * 130 + 'px' }"
              >
                <div v-for="item in info.skillList" class="skillItem">
                  <i class="el-icon-close" @click="del(item.id, 1)"></i>
                  <el-image :src="item.img"></el-image>
                  <p>{{ item.name }}</p>
                </div>
              </div>
              <p v-else class="text-center" style="margin: 50px 0; color: #999">
                暂无技能
              </p>
            </div>
            <div class="commentList">
              <h4 class="mg-t-30" style="font-size: 20px">动态</h4>
              <div v-if="info.knowledgeList.length > 0">
                <div class="item mg-t-20" v-for="item in info.knowledgeList">
                  <div class="flex space-between">
                    <span class="color-999">{{ item.gmtCreated }}</span>
                    <span class="color-999 del" @click="del(item.id, 2)">删除</span>
                  </div>
                  <div class="comment">
                    {{ item.content }}
                  </div>
                </div>
              </div>
              <p v-else class="text-center" style="margin: 50px 0; color: #999">
                暂无动态
              </p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="研究" name="second">
            <span slot="label">
              <img
                class="second"
                v-if="activeName == 'second'"
                src="../../static/images/icon_7_1.png"
                alt=""
              />
              <img class="second" v-else src="../../static/images/icon_7.png" alt="" />
              研究
            </span>
            <ul v-if="studyList.length > 0">
              <li v-for="(item, index) in studyList">
                <div class="flex space-between">
                  <span>{{ item.gmtCreated }}</span>
                  <span style="cursor: pointer" @click="del(item.id, 7)">删除</span>
                </div>
                <a href="#" @click="editResearch(item)">
                  <div class="flex space-between align-center">
                    <p class="title">{{ item.title }}</p>
                    <img src="../../static/images/arrow-right.png" alt="" />
                  </div>
                </a>
              </li>
            </ul>
            <p v-else class="text-center" style="margin: 50px 0; color: #999">暂无研究</p>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" width="40%" center>
      <p style="font-size: 50px; text-align: center">确认收藏项目?</p>
      <p
        style="
          font-size: 12px;
          color: rgb(245, 89, 89);
          display: block;
          margin-top: 20px;
          text-align: center;
        "
      >
        收藏后该项目出现在“学院-我的-收藏中”
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getShareProject">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      dialogVisible: false,
      type: "parent",
      activeName: "first",
      projectList: [],
      mainProject: [],
      currentLevel1Index: "",
      currentLevel2Index: "",
      form: {},
      info: {},
      studyList: [],
    };
  },

  created() {
    this.form = this.$route.query;
    // this.getListProject();
    this.getShareProjectInfo();
  },
  methods: {
    changeLiked(item) {
      if (item.isLiked) {
        this.$post(`/project-share/cancel-like`, {
          id: item.id,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("取消点赞成功！");
            item.isLiked = !item.isLiked;
            item.likeNum -= 1;
          }
        });
      } else {
        this.$post(`/project-share/add-like`, {
          id: item.id,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("点赞成功！");
            item.isLiked = !item.isLiked;
            item.likeNum += 1;
          }
        });
      }
    },
    choose(id, n, type) {
      this.type = type;
      if (n == 1) {
        this.currentLevel1Index = id;
        this.currentLevel2Index = "";
      } else {
        this.currentLevel2Index = id;
        this.currentLevel1Index = "";
      }
      this.getProjectInfo(id);
    },
    handleClick(v) {
      console.log(v);
      this.activeName = v;
    },
    getProjectInfo(id) {
      if (this.type == "parent") {
        this.$get(`/wd/getProjectDetail?projectId=${id}`).then((res) => {
          this.info = res.data.data;
        });
      } else {
        this.$get(`/project_sub/getProjectDetail?projectId=${id}`).then((res) => {
          this.info = res.data.data;
        });
      }
      console.log(this.info);
    },
    getListProject() {
      this.projectList = [];
      this.mainProject = [];

      this.$get(`/wd/listProject?status=3 `).then((res) => {
        this.projectList = res.data.data;

        this.$route.query.mainProjectIds.map((item) => {
          this.projectList.map((jtem) => {
            if (item == jtem.id) {
              this.mainProject.push(jtem);
            }
          });
        });
        this.getProjectInfo(this.mainProject[0].id);
        this.getStudyList(this.mainProject[0].id);
        this.currentLevel1Index = this.mainProject[0].id;
        console.log(this.mainProject);
      });
    },
    getShareProjectInfo() {
      this.$get(
        `/project-share/get-project-share-detail-by-id?status=3&projectShareId=` +
          this.$route.query.id
      ).then((res) => {
        this.mainProject = res.data.data.projectList;
        this.getProjectInfo(this.mainProject[0].id);
        this.currentLevel1Index = this.mainProject[0].id;
        this.getStudyList(this.mainProject[0].id);
      });
    },
    getStudyList(id) {
      this.$get(`/wd/listProjectResearchByProjectId?projectId=${id}`).then((res) => {
        this.studyList = res.data.data;
      });
    },
    getShareProject() {
      this.$post(`/project-share/draw`, {
        id: this.$route.query.id,
      }).then((res) => {
        this.$message.success("收藏成功！请去学院-我的-收藏中列表中查看");
        this.dialogVisible = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tag {
  background-color: #41c68e;
  color: #fff;
  border-color: #41c68e;
}
.main {
  height: 100vh;
  padding-left: 70px;
  .profiles {
    min-width: 420px;
    padding: 30px 38px;
    box-sizing: border-box;
    width: 32%;
    height: 100%;
    background: #fff;
    .getProject {
      text-align: center;
      width: 100px;
      height: 30px;
      line-height: 30px;
      background: #41c68e;
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      z-index: 2;
    }
    .tag {
      margin: 10px 0;
    }
    .menuInfo {
      .projectBox {
        max-height: 50vh;
        overflow-y: auto;
        .item {
          padding: 5px;
          > h1 {
            cursor: pointer;
            vertical-align: middle;
            padding-left: 10px;
            &:hover,
            &.on {
              background: #41c68e;
              span {
                color: #fff;
              }
            }
          }
          h1 span {
            font-size: 22px;
            color: #333;
          }
          .title {
            width: 88%;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #333;
            vertical-align: middle;
            line-height: 46px;
          }
          .childItem {
            h1 {
              padding-left: 60px;
              font-size: 22px;
              position: relative;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #333;
              white-space: nowrap;
              line-height: 46px;
              cursor: pointer;
              &:hover,
              &.on {
                color: #fff;
                background: #41c68e;
                &::before {
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-top: 15px solid #fff;
                }
              }
              &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 15px solid black;
                position: absolute;
                left: 35px;
                top: 16px;
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
      .img {
        margin-top: 20px;
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        height: 140px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 30px 20px;
        box-sizing: border-box;
        position: relative;
        h1 {
          color: #fff;
          position: relative;
          z-index: 2;
          font-size: 22px;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 定义显示的最大行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .layout {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
      }
      .text {
        padding: 20px;
        box-sizing: border-box;
        background: #fff;
        border: 1px solid rgb(216, 216, 216);
        border-top: none;
        margin-top: -10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        h3 {
          color: #999;
          font-weight: normal;
          height: 60px;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 定义显示的最大行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 20px;
          span {
            color: #000;
          }
          margin-bottom: 15px;
        }
        .bot {
          span.logo {
            color: #000;
            display: inline-block;
            padding: 2px 5px;
            border: 1px solid #000;
            border-radius: 4px;
          }
          i {
            font-style: normal;
          }
        }
      }
    }
    .arrow {
      width: 10px;
      height: 20px;
      font-size: 18px;
    }
    .left {
      cursor: pointer;
    }
    .sold {
      background: none !important;
      color: #41c68e;
      &.el-button--info {
        color: #fff;
        background: #999 !important;
      }
    }
    .content {
      padding: 25px 38px;
      box-sizing: border-box;
      height: 100%;
      width: 68%;
      border-left: 1px solid #888;
      // min-width: 730px;
      background: #fff;
      overflow: hidden;
    }
  }
  ::v-deep .el-button--primary {
    background-color: #41c68e !important;
    border-color: #41c68e !important;
    padding: 10px 20px;
  }
  .content {
    flex: 1;
    padding: 25px 38px;
    box-sizing: border-box;
    background: #fff;
    border-left: 1px solid rgb(212, 212, 212);
    .childTag {
      padding: 5px 10px;
      background: #59b5d2;
      color: #fff;
      display: inline-block;
      margin-left: 20px;
      border-radius: 4px;
    }
    .nav {
      .arrow {
        width: 10px;
        height: 20px;
        font-size: 18px;
      }
      .left {
        cursor: pointer;
      }
      .sold {
        background: none !important;
        color: #41c68e;
        &.el-button--info {
          color: #fff;
          background: #999 !important;
        }
      }
    }

    .infomation {
      height: calc(100vh - 100px);
      overflow-y: auto;
      overflow-x: visible;
      padding-right: 20px;
      padding-top: 10px;
      box-sizing: border-box;
      position: relative;
      padding-left: 10px;
      .first {
        width: 20px;
        height: 20px;
        vertical-align: sub;
      }
      .second {
        width: 25px;
        height: 25px;
        vertical-align: sub;
      }
      .el-tabs {
        margin-top: 20px;
      }
      ::v-deep .el-tabs__item span {
        font-size: 18px !important;
        font-weight: bold;
        color: #999;
      }
      .el-tabs__item.is-active span {
        color: #000;
      }
      .layout {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
      }
      .el-input {
        width: 300px;
      }

      .el-tag {
        font-size: 16px;
      }

      .el-tag + .el-tag {
        margin-left: 20px;
      }
      .el-tag:nth-child(3n + 1) {
        margin-left: 0;
      }
      .comment {
        padding: 20px;
        background: #f7f7f8;
        border-radius: 4px;
        margin-top: 20px;
        line-height: 26px;
        margin-bottom: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
      .del {
        cursor: pointer;
        &:hover {
          color: #666;
        }
      }
    }

    ul li {
      margin-top: 10px;
    }
    ul li span {
      color: #999;
    }
    ul li a {
      display: block;
      padding: 20px;
      border-radius: 10px;
      background: #f7f8f8;
      margin-bottom: 30px;
      margin-top: 10px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    }
    ul li .title {
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 4px;
    }
    ul li img {
      width: 10px;
      height: 20px;
    }
    ::v-deep .el-tabs__active-bar {
      background: #41c68e;
      height: 3px;
      border-radius: 15px;
    }
    ::v-deep .el-button--primary {
      background-color: #41c68e !important;
      border-color: #41c68e !important;
      padding: 10px 20px;
    }
    .el-tag {
      width: 30%;
      padding: 10px 0;
      height: auto;
      font-size: 16px;
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      border: none;
    }
    .el-tag.el-tag--info {
      color: #000;
      word-break: break-all;
      white-space: break-spaces;
      padding: 15px;
      height: 70px;
      line-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f4f4f5 !important;
    }
    .el-button + .el-button {
      margin-left: 20px;
    }
    ::v-deep .el-textarea__inner {
      height: auto !important;
      min-height: 300px !important;
    }
    ::v-deep .skill .el-textarea__inner {
      height: 100px !important;
      min-height: 100px !important;
    }
    .el-tag {
      color: #000;
      position: relative;
    }
    .skillItem {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    }
    .skillItem .el-icon-close {
      top: 5px;
      right: 5px;
      position: absolute;
      background: red;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      line-height: 16px;
      color: #fff;
      top: -5px;
      right: -5px;
      position: absolute;
      width: 17px;
      height: 17px;
      line-height: 17px;
    }
    ::v-deep .el-tag.el-tag--success {
      background: linear-gradient(53deg, #ffb800 0%, #ff8b04 31.26%, #ed0404 97.45%);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      color: #fff;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ::v-deep .finish.el-button--primary {
      margin-right: 20px;
      background: linear-gradient(
        53deg,
        #ffb800 0%,
        #ff8b04 31.26%,
        #ed0404 97.45%
      ) !important;
      border-color: transparent !important;
    }
    ::v-deep .summarize.el-button--primary {
      height: 38px;
    }
    ::v-deep .addSkill.el-button--primary {
      padding: 5px 20px;
    }
    .skillItemBox {
      overflow-x: auto;
      padding: 20px 0;
    }
    ::v-deep .el-tabs__content {
      overflow: visible;
    }
    .skillItem {
      width: 120px;
      background: #f7f8f8;
      border-radius: 8px;
      text-align: center;
      position: relative;
      p {
        margin-bottom: 15px;
      }
    }
    .skillItem .el-image {
      width: 50px;
      height: 50px;
      display: block;
      margin: 15px auto;
    }
    .skillItem + .skillItem {
      margin-left: 3%;
    }

    ::v-deep .skill .el-input__inner,
    ::v-deep .skill .el-textarea__inner {
      border-radius: 0;
      border: none;
      background: #f7f7f8;
      box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
    }
    ::v-deep .el-input .el-input__count .el-input__count-inner {
      background: none;
    }
    ::v-deep .el-tag .el-icon-close::before {
      background: red;
      border-radius: 50%;
      color: #fff;
    }
  }
}
</style>
