<template>
  <div class="header">
    <div class="logo">
      <img src="../static/images/icon_logo.png" alt="" />
    </div>
    <ul>
      <li
        v-for="(item, index) in tabs"
        :key="index"
        :class="{ on: currentUrl == item.url }"
        @click="changeTab(item.url)"
      >
        <router-link :to="item.url">
          <img :src="currentUrl == item.url ? item.imgUrl2 : item.imgUrl" alt="" />
        </router-link>
        <span class="title">{{ item.title }}</span>
      </li>
    </ul>
    <div class="know">
      <a href="https://docs.qq.com/aio/DYWVxYklHbm93RkVp" target="_blank">
        <img src="../static/images/icon_5.png" alt="" />
      </a>
      <span class="title">百科</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-nav",
  data() {
    return {
      currentUrl: "/profile",
      tabs: [
        {
          title: "问道",
          url: "/project",
          imgUrl: require("../static/images/icon_1.png"),
          imgUrl2: require("../static/images/icon_1_1.png"),
        },
        {
          title: "学院",
          url: "/school",
          imgUrl: require("../static/images/school_2.png"),
          imgUrl2: require("../static/images/school_1.png"),
        },
        {
          title: "我的",
          url: "/profile",
          imgUrl: require("../static/images/icon_2.png"),
          imgUrl2: require("../static/images/icon_2_1.png"),
        },
        {
          title: "灵兰之境",
          url: "/flower",
          imgUrl: require("../static/images/icon_3.png"),
          imgUrl2: require("../static/images/icon_3_1.png"),
        },
        {
          title: "市井",
          url: "/shop",
          imgUrl: require("../static/images/icon_4.png"),
          imgUrl2: require("../static/images/icon_4_1.png"),
        },
      ],
    };
  },
  created() {
    console.log(this.$route);
    this.tabs.map((item, index) => {
      console.log(item.url);
      console.log(this.$route.path.includes(index, item.url));
      if (this.$route.path.includes(item.url)) {
        this.currentUrl = item.url;
        return false;
      }
    });
    console.log(this.currentUrl);
  },
  methods: {
    changeTab(url) {
      this.currentUrl = url;
      // this.$router.replace(url)
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 70px;
  height: 100vh;
  background: #2e2e2e;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .logo {
    text-align: center;
    margin: 25px 0;
    img {
      width: 50px;
      height: 50px;
    }
  }
  ul {
    li {
      padding: 10px 0;
      position: relative;

      a {
        display: block;
        text-align: center;
      }
      &.on {
        background: #41c68e;
      }
      &:hover .title {
        display: block;
      }
    }
  }
  ul li img {
    width: 30px;
    height: 30px;
  }
  .title {
    display: none;
    white-space: nowrap;
    padding: 5px 10px;
    color: #fff;
    background: #000000;
    position: absolute;
    left: 90%;
    top: 50%;
    transform: translateY(-50%);
    min-width: 40px;
    text-align: center;
  }
  .know {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    img {
      height: 30px;
      width: 30px;
    }
    &:hover .title {
      display: block;
    }
  }
}
</style>
