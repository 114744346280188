<template>
  <div class="info">
    <div class="nav flex space-between align-center">
      <div class="left flex align-center" @click="$router.push('/profile/seed')">
        <el-image
          class="arrow"
          :src="require('@/static/images/left-arrow.png')"
          lazy
        ></el-image>
        <p class="mg-l-20">返回</p>
      </div>
      <div class="right mg-r-20">
        <el-button
          type="primary"
          class="sold"
          @click="sendVisible = true"
          v-if="type == 2"
          >售卖</el-button
        >
        <el-button
          class="mg-l-20"
          type="primary"
          @click="seedVisible = true"
          v-if="type == 1"
          >种植</el-button
        >
      </div>
    </div>
    <div class="infomation" v-if="Object.keys(info).length > 0">
      <div class="imgBox">
        <div class="img flex space-center">
          <el-image class="flower" :src="info.dto.img" lazy></el-image>
        </div>
      </div>
      <div class="text">
        <div class="flex">
          <div>
            <p class="label">名称：</p>
            <b style="font-size: 17px">{{ info.dto.name }}</b>
          </div>
          <div style="margin-left: 100px">
            <p class="label">属性：</p>
            <b style="font-size: 17px">{{ info.dto.categoryName }}</b>
          </div>
          <div style="margin-left: 100px">
            <p class="label">价格：</p>
            <b style="font-size: 17px">{{ info.dto.price }}信用点</b>
          </div>
        </div>
        <div>
          <div class="flex">
            <p class="label">简介：</p>
            <p class="pro" style="color: #666">{{ info.dto.blurb }}</p>
          </div>
        </div>
        <div>
          <div class="flex">
            <p class="label">歌语：</p>
            <b style="font-size: 16px; color: #41c68e">{{ info.dto.songLan }}</b>
          </div>
        </div>
        <div class="flex">
          <div v-if="type == 1">
            <p class="label">掉落材料：</p>
            <b style="font-size: 17px" v-if="info.dto.material != null">{{
              info.dto.material.name
            }}</b>
          </div>
          <div :style="{ marginLeft: type == 1 ? '100px' : '0' }">
            <p class="label" style="display: inline-block">材料售卖价值：</p>
            <b style="font-size: 17px; color: #ffb800"
              >{{ info.saleCreditPrice || 0 }} 信用点</b
            >
          </div>
        </div>
        <!-- <div>
          <p class="mg-b-20">
            <p class="label">变种：</p>
          </p>
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                v-for="(list, index) in items"
                :key="index"
                class="swiper-slide"
              >
                <el-image class="skin" :src="list.src"></el-image>
                <p class="text-center">{{ list.name }}</p>
              </div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div> -->
      </div>
    </div>
    <el-dialog title="" :visible.sync="sendVisible" width="40%" center>
      <p style="font-size: 24px; text-align: center" v-if="Object.keys(info).length > 0">
        确定以<span style="color: #ffb800; font-size: 24px"
          >{{ info.dto.price }}信用点</span
        >售卖该物品？
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saleMemberCapital">确 定</el-button>
        <el-button @click="sendVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="" :visible.sync="seedVisible" width="40%" center>
      <p style="font-size: 24px; text-align: center">确定种下该种子？</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="plantTree">确 定</el-button>
        <el-button @click="seedVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  name: "seedInfo",
  data() {
    return {
      sendVisible: false,
      seedVisible: false,
      info: {},
      type: "",
      items: [
        {
          name: "香槟夜兰",
          src: require("@/static/images/skin1.png"),
        },
        {
          name: "香槟夜兰",
          src: require("@/static/images/skin2.png"),
        },
        {
          name: "香槟夜兰",
          src: require("@/static/images/skin1.png"),
        },
        {
          name: "香槟夜兰",
          src: require("@/static/images/skin2.png"),
        },
      ],
    };
  },
  created() {
    this.type = this.$route.query.type;
    if (this.$route.query.type == 1) {
      this.getListMemberCapital();
    } else if (this.$route.query.type == 2) {
      this.getMemberCapitalMaterialDetail();
    } else {
      this.getMemberCapitalSkinDetail();
    }
    let i = setTimeout(() => {
      new Swiper(".swiper-container", {
        slidesPerView: 3,
        spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          // 这个是获取当前的滑块的索引
          slideChange: function () {
            //  that.active = this.activeIndex
          },
        },
      });
      clearTimeout(i);
    }, 500);
  },
  methods: {
    // 获取种子详情
    getListMemberCapital() {
      this.$get(
        `/my/getMemberCapitalSeedDetail?memberCapitalId=${this.$route.query.id}`
      ).then((res) => {
        this.info = res.data.data;
        this.info.dto = this.info.seedDTO;
      });
    },
    // 获取材料详情
    getMemberCapitalMaterialDetail() {
      this.$get(
        `/my/getMemberCapitalMaterialDetail?memberCapitalId=${this.$route.query.id}`
      ).then((res) => {
        this.info = res.data.data;
        this.info.dto = this.info.materialDTO;
      });
    },
    // 获取皮肤详情
    getMemberCapitalSkinDetail() {
      this.$get(
        `/my/getMemberCapitalSkinDetail?memberCapitalId=${this.$route.query.id}`
      ).then((res) => {
        this.info = res.data.data;
        this.info.dto = this.info.skinDTO;
      });
    },
    // 售卖种子
    saleMemberCapital() {
      this.$post(`/my/saleMemberCapital`, {
        memberCapitalId: this.$route.query.id,
        type: this.$route.query.type,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("售卖成功！");
          this.sendVisible = false;
          let i = setTimeout(() => {
            this.$router.push("/profile");
            this.$parent.getMemberInfo();
            clearTimeout(i);
          }, 1000);
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    // 种植种子
    plantTree() {
      this.$post(`/my/plantTree`, {
        memberCapitalId: this.$route.query.id,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("种植成功！");
          this.seedVisible = false;
          let i = setTimeout(() => {
            this.$router.push("/profile/seed");
          }, 1000);
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  .skin {
    width: 100%;
    height: 10vw;
  }
  height: 100%;
  .nav {
    .arrow {
      width: 10px;
      height: 20px;
      font-size: 18px;
    }
    .left {
      cursor: pointer;
    }
    .sold {
      background: none !important;
      color: #41c68e;
    }
  }
  .flower {
    width: 200px;
  }
  .infomation {
    height: calc(100% - 36px);
    padding: 0 20px;
    box-sizing: border-box;
    overflow-y: auto;
    .imgBox {
      margin: 20px;
      .img .el-image {
        border: 1px solid #eee;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
      }
    }
    .img {
      margin: 20px auto;
    }
    .text {
      margin-top: 20px;
    }
    div {
      margin-top: 20px;

      p.label {
        display: inline-block;
        width: 100px;
        color: #999;
      }
      .pro {
        line-height: 26px;
        flex: 1;
      }
    }
  }
}
::v-deep .el-button--primary {
  background-color: #41c68e !important;
  border-color: #41c68e !important;
  padding: 10px 20px;
}
.el-button + .el-button {
  margin-left: 20px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff;
}
.swiper-button-next,
.swiper-button-prev {
  top: 40%;
}
@media screen and (min-width: 1600px) {
  .info .infomation p span.label {
    width: 120px;
  }
  .info .infomation p .pro {
    width: 90%;
  }
}
</style>
