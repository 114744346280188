<template>
  <div class="box">
    <div class="tab flex space-between">
      <ul>
        <li
          v-for="(item, index) in tabs"
          :key="index"
          @click="changeTab(index)"
          :class="{ on: current == index }"
        >
          {{ item }}
        </li>
      </ul>
      <div class="wallet" style="margin-right: 40px">
        <div class="flex space-arround">
          <div class="line flex align-center mg-r-20">
            <el-image
              class="star mg-r-10"
              :src="require('@/static/images/card.png')"
              lazy
            ></el-image>
            <p>
              <span>信用点：</span><b>{{ info.memberCapitalDO.creditScore }}</b>
            </p>
          </div>
          <div class="line flex align-center">
            <el-image
              class="star mg-r-10"
              :src="require('@/static/images/circle.png')"
              lazy
            ></el-image>
            <p>
              <span>火种：</span><b>{{ info.memberCapitalDO.fireScore }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="list" v-if="list.length > 0">
      <div class="listBox flex flex-wrap">
        <div
          v-for="(item, index) in list"
          :key="item.id"
          class="item flex flex-column space-center"
          @click="
            $router.push(`/profile/seedInfo?id=${item.memberCapitalId}&type=${type}`)
          "
        >
          <div class="img flex space-center">
            <el-image class="flower" :src="item.img" lazy></el-image>
          </div>
          <p class="text-center mg-t-20">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty description="暂无"></el-empty>
    </div>
  </div>
</template>
<script>
export default {
  name: "seed",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      tabs: ["种子", "材料", "皮肤"],
      current: 0,
      list: [],
      material: [],
      skin: [],
      type: 1,
      info: {},
    };
  },
  created() {
    this.info = JSON.parse(localStorage.getItem("userInfo"));
    this.getListMemberCapital();
  },
  methods: {
    // 获取种子
    getListMemberCapital() {
      this.$get(`/my/listMemberCapital?type=${this.type}`).then((res) => {
        this.list = res.data.data;
      });
    },
    changeTab(i) {
      this.current = i;
      this.type = i + 1;
      console.log(this.type);
      this.getListMemberCapital();
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  height: calc(100% - 42px);
  ul {
    width: 50%;
    padding: 0 10px;
    box-sizing: border-box;
    li {
      display: inline-block;
      padding-right: 50px;
      cursor: pointer;
      color: #999;
      font-size: 20px;

      &.on {
        color: #41c68e;
      }
    }
  }
  .list {
    width: 100%;
    height: calc(100% - 10px);
    box-sizing: border-box;
    overflow-y: auto;
    padding: 0 10px;
    .item {
      width: 23%;
      padding: 20px 0;
      margin-top: 20px;
      cursor: pointer;
      .img {
        background: #f7f8f8;
        box-shadow: 0px 0 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
      }
      p {
        font-size: 18px;
        font-weight: bold;
      }
    }
    .flower {
      width: 113px;
    }
    .item + .item {
      margin-left: 2%;
    }
    .item:nth-child(4n + 1) {
      margin-left: 0;
    }
    &.show {
      display: block;
    }
  }
}
</style>
