<template>
  <el-dialog
    title="设置"
    :visible.sync="setVisible"
    width="1200px"
    center
    @close="closeVisible"
    :show-close="false"
  >
    <div class="left flex align-center" @click="updateVisible">
      <el-image
        class="arrow"
        :src="require('@/static/images/left-arrow.png')"
        lazy
      ></el-image>
      <p class="mg-l-20">返回</p>
    </div>
    <div class="main flex">
      <div class="left-nav">
        <ul>
          <li
            v-for="(item, index) in tabs"
            :key="index"
            :class="{ on: current == index }"
            @click="changeTab(index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="right-content">
        <div v-if="current == 0">
          <div class="flex">
            <span class="label">头像：</span>
            <div class="flex">
              <div
                class="img"
                v-for="(item, index) in imgList"
                :key="index"
                :class="{ choose: chooseIndex == index }"
                @click="selectAvatar(index)"
              >
                <el-image
                  class="avatar"
                  :src="item"
                  lazy
                ></el-image>
              </div>
            </div>
          </div>
          <div class="flex">
            <span class="label">当前名称：</span>
            <b>{{ oldName }}</b>
          </div>
          <div class="flex align-center">
            <span class="label">修改名称：</span>
            <el-input
              v-model="name"
              type="text"
              maxlength="10"
              show-word-limit
              placeholder="请输入最新名称"
            ></el-input>
          </div>
          <div class="flex">
            <span class="label"></span>
            <div>
              <el-button type="primary" @click="submitName">保存</el-button>
            </div>
          </div>
        </div>

        <!-- 修改邮箱 -->
        <div v-if="current == 1">
          <div class="align-center">
            <span class="label">绑定邮箱：</span>
            <el-input v-model="oldEmail" disabled></el-input>
            <el-button
              type="primary"
              class="getCode"
              @click="sendEmailCode"
              :icon="isLoading ? 'el-icon-loading' : ''"
              :disabled="countdown > 0"
              >{{ tips }}</el-button
            >
          </div>
          <div class="flex align-center">
            <span class="label">验证码：</span>
            <el-input
              type="text"
              v-model="code"
              placeholder="请输入验证码"
            ></el-input>
          </div>
          <div class="flex align-center">
            <span class="label">新邮箱：</span>
            <el-input
              type="text"
              v-model="email"
              placeholder="请输入新邮箱"
            ></el-input>
          </div>
          <div class="flex align-center">
            <span class="label">新邮箱：</span>
            <el-input
              type="text"
              v-model="emailAgain"
              placeholder="请再次输入新邮箱"
            ></el-input>
          </div>
          <!-- <div class="flex align-center">
            <span class="label">新邮箱：</span>
            <el-input
              type="text"
              v-model="email"
              placeholder="请输入最新邮箱"
            ></el-input>
          </div>
          <div class="flex align-center">
            <span class="label">验证码：</span>
            <el-input
              type="text"
              v-model="code"
              placeholder="请输入验证码"
            ></el-input>
            <el-button
              type="primary"
              class="getCode"
              @click="sendEmailCode"
              :icon="isLoading ? 'el-icon-loading' : ''"
              :disabled="countdown > 0"
              >{{ tips }}</el-button
            >
          </div> -->
          <div class="flex">
            <span class="label"></span>
            <div>
              <el-button type="primary" @click="updateEmailBox">保存</el-button>
            </div>
          </div>
        </div>

        <!-- 修改密码 -->
        <div v-if="current == 2">
          <div class="flex align-center">
            <span class="label">新密码：</span>
            <el-input type="text" placeholder="请输入最新邮箱"></el-input>
          </div>
          <div class="flex align-center">
            <span class="label">确认密码：</span>
            <el-input type="text" placeholder="请输入最新邮箱"></el-input>
          </div>
          <div class="flex align-center">
            <span class="label">验证码：</span>
            <el-input type="text" placeholder="请输入邮箱验证码"></el-input>
            <el-button type="primary" class="getCode">获取验证码</el-button>
          </div>
          <div class="flex">
            <span class="label"></span>
            <div>
              <el-button type="primary" @click="submit">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "",
  props: {
    setVisible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      name: "",
      memberDetailDTO: {
        name: "",
      },
      // , "修改密码"
      imgList: [
        "/image/avatar/1.png",
        "/image/avatar/2.png",
        "/image/avatar/3.png",
      ],
      isLoading: false,
      tips: "获取验证码",
      tabs: ["个人信息", "修改邮箱"],
      name: "",
      email: "",
      emailAgain: "",
      oldName: "",
      oldEmail: "",
      current: 0,
      chooseIndex: 0,
      theme: "UPDATE_EMAIL",
      countdown: 0,
      timer: null,
      sendEmailToken: "",
      code: "",
      oldAvatar: "",
    };
  },
  watch: {
    info(n, o) {
      this.oldName = n.name;
      this.oldEmail = n.email;
      this.oldAvatar = n.avatar;
      console.log();
      if (this.oldAvatar.includes(1)) {
        this.chooseIndex = 0;
      } else if (this.oldAvatar.includes(2)) {
        this.chooseIndex = 1;
      } else {
        this.chooseIndex = 2;
      }
    },
  },
  methods: {
    submitName() {
      if (this.current == 1) {
        let reg = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        if (this.email === "") {
          this.$message.error(new Error("请输入邮箱地址"));
          return false;
        } else if (!reg.test(this.email)) {
          this.$message.error("请输入正确的邮箱格式");
          return false;
        }
      } else {
        if (this.name == "") {
          this.name = this.oldName;
        }
      }

      const { memberId } = JSON.parse(
        localStorage.getItem("userInfo")
      ).memberCapitalDO;
      const {
        phone,
        idCardNo,
        birthday,
        avatar,
        starGenieName,
        starGenieAvatar,
        name,
        email,
      } = JSON.parse(localStorage.getItem("userInfo"));
      this.$post("/my/editMemberInfo", {
        id: memberId,
        phone,
        email: email,
        name: this.name == "" ? name : this.name,
        idCardNo,
        birthday,
        avatar: this.oldAvatar,
        starGenieName,
        starGenieAvatar,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("修改成功！");
          this.$emit("updateInfo");
          this.email = "";
          this.name = "";
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },

    sendEmailCode() {
      this.isLoading = true;

      this.$post("/email/sendEmail", {
        theme: this.theme,
        emailBox: this.oldEmail,
      }).then((res) => {
        this.isLoading = false;
        if (res.data.success) {
          this.sendEmailToken = res.data.data;
          this.$message.success("获取成功!");
          // 倒计时
          if (this.countdown > 0) {
            return;
          }
          const countdownDuration = 60;
          this.countdown = countdownDuration;
          this.tips = this.countdown + "s后重新获取";
          // 创建定时器，每秒减少倒计时
          this.timer = setInterval(() => {
            this.countdown--;
            this.tips = this.countdown + "s后重新获取";
            // 如果倒计时结束，清除定时器
            if (this.countdown <= 0) {
              this.countdown = 0;
              this.tips = "获取验证码";
              this.countdown = 0;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    // 修改邮箱
    updateEmailBox() {
      if (this.code == "") {
        this.$message.error("验证码不能为空");
        return false;
      }
      let reg = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (this.email === "") {
        this.$message.error(new Error("请输入邮箱地址"));
        return false;
      } else if (!reg.test(this.email)) {
        this.$message.error("请输入正确的邮箱格式");
        return false;
      }
      if (this.email != this.emailAgain) {
        this.$message.error("两次输入的新邮箱不一致，请检查");
        return false;
      }
      if (this.sendEmailToken == "") {
        this.$message.error("请先获取验证码");
        return false;
      }
      this.$post("/auth/updateEmailBox", {
        newEmailBox: this.email,
        code: this.code,
        sendEmailToken: this.sendEmailToken,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("修改成功！");
          this.$emit("updateInfo");
          this.email = "";
          this.name = "";
          this.$emit("reLogin", false);
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    closeVisible() {
      this.name = "";
      this.email = "";
      this.code = "";
      this.countdown = 0;
      clearTimeout(this.timer);
      this.timer = null;
      this.$emit("update:setVisible", false);
    },
    changeTab(index) {
      this.current = index;
    },
    selectAvatar(index) {
      this.chooseIndex = index;
      this.oldAvatar = this.imgList[index];
    },
    updateVisible() {
      this.$emit("updateVisible");
    },
  },
};
</script>
<style lang="scss" scoped>
.left {
  position: absolute;
  top: 22px;
  left: 20px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  color: #000;
  .arrow {
    width: 10px;
    height: 20px;
    font-size: 18px;
  }
}

.left-nav {
  background: #f7f8f8;
  ul {
    width: 150px;
    li {
      padding: 20px 0;
      text-align: center;
      cursor: pointer;
      &.on {
        background: #41c68e;
        color: #fff;
      }
    }
  }
}
.right-content {
  height: 65vh;
  width: 70%;
  padding: 20px 50px;
  box-sizing: border-box;
  > div > div {
    margin-top: 20px;
  }
  .label {
    width: 100px;
    display: inline-block;
  }
  .img {
    padding: 10px;
    border-radius: 6px;
    border: 1px solid transparent;
    &.choose {
      border-color: #41c68e;
    }
  }
}
.avatar {
  max-width: 100px;
  border-radius: 8px;
}
::v-deep .el-dialog__header {
  padding: 20px;
  background: #f7f8f8;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__body {
  font-size: 16px;
}
.el-input {
  width: 300px;
}
.el-button.getCode {
  margin-left: 20px;
}
::v-deep .el-button--primary.is-disabled {
  background-color: #666 !important;
  border-color: #666 !important;
}
</style>
