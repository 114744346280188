<template>
  <el-dialog
    title=""
    :visible.sync="visible"
    width="1200px"
    center
    @close="closeVisible"
    :show-close="false"
  >
    <div class="left flex align-center" @click="updateVisible">
      <el-image
        class="arrow"
        :src="require('@/static/images/left-arrow.png')"
        lazy
      ></el-image>
      <p class="mg-l-20">返回</p>
    </div>
    <div class="right">
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
    <div class="main">
      <div class="flex mg-t-20 align-center">
        <span class="bold">主题：</span>
        <el-input
          v-model="title"
          class="mainTitle"
          type="text"
          maxlength="20"
          show-word-limit
        ></el-input>
      </div>
      <div>
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="content"
          maxlength="2000"
          show-word-limit
          :rows="15"
        >
        </el-input>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    researchContent: {
      type: String,
      default: "",
    },
    researchTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: "",
      content: "",
    };
  },
  mounted() {},
  watch: {
    researchTitle(n) {
      this.title = n;
    },
    researchContent(n) {
      this.content = n;
    },
  },
  methods: {
    updateVisible() {
      this.title = "";
      this.content = "";
      this.$emit("updateVisible", false);
    },
    closeVisible() {
      this.$emit("updateVisible", false);
    },
    submit() {
      if (this.title == "") {
        this.$message.error("请输入标题");
        return false;
      } else if (this.content == "") {
        this.$message.error("请输入内容");
        return false;
      }
      this.$emit("addResearch", [this.title, this.content]);
      this.title = "";
      this.content = "";
    },
    delTag() {},
  },
};
</script>
<style lang="scss" scoped>
.left {
  position: absolute;
  top: 22px;
  left: 20px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  color: #000;
  .arrow {
    width: 10px;
    height: 20px;
    font-size: 18px;
  }
}
.right {
  position: absolute;
  top: 22px;
  right: 20px;
}
::v-deep .mainTitle .el-input__inner {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #aaa;
}
::v-deep .node .el-input__inner {
  border-radius: 0;
  border: none;
  background: #f7f7f8;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  background: none;
}
.el-input {
  width: 94%;
}
::v-deep .el-textarea__inner {
  height: auto !important;
  min-height: 400px !important;
  margin-top: 20px;
  border: none;
  padding: 0;
}
</style>
