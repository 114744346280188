<template>
  <div class="box">
    <div class="tab flex space-between align-center">
      <ul>
        <li
          v-for="(item, index) in tabs"
          :key="index"
          @click="changeTab(index)"
          :class="{ on: current == index }"
        >
          {{ item }}
        </li>
      </ul>
      <el-button
        type="primary"
        class="createProject"
        @click="$router.push('/project/page-list/createProject?type=parent')"
        >创作</el-button
      >
    </div>
    <div class="tags search flex align-center mg-t-20 space-between">
      <div>
        <span style="margin-right: 10px">标签：</span>
        <el-tag
          :class="{ on: curretIndex == index }"
          @click="changeTag(item, index)"
          v-for="(item, index) in fourTags"
          :key="item.id"
          >{{ item.name }}</el-tag
        >
      </div>
      <div class="box" v-if="tags.length > 0">
        <img class="more_tag" src="../../../static/images/more_tag.png" alt="" />
        <div class="tag_box">
          <p>标签</p>
          <el-tag
            :class="{ on: curretIndex2 == index }"
            @click="changeTag2(item, index)"
            v-for="(item, index) in tags"
            :key="item.id"
            >{{ item.name }}</el-tag
          >
        </div>
      </div>
    </div>
    <div class="scroll-box" v-if="list.length > 0">
      <div
        class="itemBox"
        v-for="(item, index) in list"
        :key="index"
        @contextmenu.prevent="showContextMenu($event, index)"
      >
        <div class="item">
          <div
            class="open"
            :class="{ on: isOpen && currentIndex3 == index }"
            @click="openChildItem(item.id, index)"
          ></div>
          <div class="delBox">
            <div class="del">
              <span @click="showTagDialog(item, index)">标签管理</span>
              <span
                @click="
                  $router.push(
                    '/project/page-list/createProject?parentId=' +
                      item.id +
                      '&type=createChildItem' +
                      '&memberId=' +
                      item.memberId
                  )
                "
                >创建子项目</span
              >
              <span @click="delPro(item)">删除</span>
            </div>
          </div>
          <router-link :to="'/project/page-list/projectInfo?id=' + item.id">
            <div class="flex space-between align-center">
              <div class="bbox flex align-center">
                <div class="bold">
                  <span>{{ index + 1 }} 、</span>
                </div>
                <div class="flex align-center">
                  <el-image class="flower" :src="item.image" lazy></el-image>
                  <div
                    class="text flex space-between align-center"
                    style="flex: 1; border-radius: 4px"
                  >
                    <div>
                      <h2 class="title">{{ item.title }}</h2>
                      <span>{{ item.gmtCreated }}</span>
                    </div>
                    <!-- <i class="el-icon-arrow-right"></i> -->
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div
          class="childItem"
          :class="{ opend: isOpen && currentIndex3 == index }"
          v-if="childList.length > 0"
        >
          <div class="item">
            <ul v-for="item in childList" :key="item.id">
              <li>
                <router-link
                  :to="'/project/page-list/projectInfo?id=' + item.id + '&type=child'"
                  >{{ item.title }}</router-link
                >
              </li>
            </ul>
          </div>
          <!-- <p v-else class="text-center" style="color: #c7c7c7">暂无子项目</p> -->
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty description="开始创建一个项目吧！"></el-empty>
    </div>
    <el-dialog title="" :visible.sync="sendVisible" width="40%" center>
      <p style="font-size: 30px; text-align: center; margin-top: 0; margin-bottom: 20px">
        确定删除项目？
      </p>
      <p class="text-center">
        <span slot="footer" class="dialog-footer text-center">
          <el-button type="primary" @click="confirmDel">确 定</el-button>
          <el-button @click="sendVisible = false">取 消</el-button>
        </span>
      </p></el-dialog
    >

    <tags
      :visible="visible"
      :tagsList="tags"
      :hasTags="hasTags"
      :projectName="projectName"
      @updateVisible="updateVisible"
      @addTag="addNewTag"
      @submitTags="submitTags"
      @submitDel="submitDel"
      @updateTags="updateTags"
      @returnStatus="getListProject"
    />
  </div>
</template>
<script>
import tags from "../components/tags.vue";

export default {
  name: "projectList",
  components: {
    tags,
  },
  mixins: [],
  props: {},
  data() {
    return {
      curretIndex: 0,
      curretIndex2: -1,
      currentIndex3: -1,
      visible: false,
      status: 1,
      tabs: ["进行中", "休眠中", "已完成"],
      current: 0,
      list: [],
      childList: [],
      projectName: "",
      id: "",
      sendVisible: false,
      fourTags: [],
      tags: [],
      tagsList: [],
      hasTags: [],
      tagId: "",
      currentProjectIndex: "",
      currentIndex: null,
      isOpen: false,
    };
  },
  created() {
    this.getListProject();
    this.getAllTags();
  },
  methods: {
    openChildItem(id, index) {
      if (this.currentIndex3 == index) {
        this.isOpen = false;
        this.currentIndex3 = -1;
      } else {
        this.currentIndex3 = index;
        this.isOpen = true;
        this.getChildItem(id);
      }
    },
    // 查询子项目
    getChildItem(parentId) {
      this.$get(`/project_sub/list-by-parent-id?parentProjectId=${parentId}`).then(
        (res) => {
          this.childList = res.data.data;
        }
      );
    },
    changeTag(item, index) {
      this.tagId = item.id;
      this.childList = [];
      this.isOpen = false;
      this.curretIndex = index;
      this.currentIndex3 = -1;
      if (index == 0) {
        this.curretIndex2 = "";
      } else {
        this.curretIndex2 = index - 1;
      }
      this.getListProject();
    },
    changeTag2(item, index) {
      this.tagId = item.id;
      this.isOpen = false;
      this.curretIndex2 = index;
      this.currentIndex = -1;
      // if (index != 0) {
      //   this.curretIndex = index + 1;
      // } else {
      //   this.curretIndex = 1;
      // }
      this.getListProject();
    },
    updateVisible(v) {
      this.visible = v;
    },
    // 所有标签
    getAllTags() {
      this.$get(`/tag/listAllTag`).then((res) => {
        this.tags = res.data.data;
        this.fourTags = res.data.data.slice(0, 3);
        this.fourTags.unshift(
          {
            name: "全部",
            id: "",
          },
          {
            name: "未标签",
            id: "-1",
          }
        );
      });
      console.log(this.fourTags);
    },
    // 更新标签
    updateTags(arr) {
      this.$post(`/tag/updateTagRelation`, {
        projectId: this.id,
        tagId: arr[1],
        relationSwitch: arr[0],
      }).then((res) => {
        if (res.data.data == true) {
          if (!arr[0]) {
            this.$message.info("取消关联成功！");
          } else {
            this.$message.success("关联成功！");
          }
          this.getAllTags();
          this.getListProject();
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    // 新增标签
    addNewTag(tag) {
      this.$post(`/tag/addTagForProject`, {
        projectId: this.id,
        tagNames: [tag],
      }).then((res) => {
        if (res.data.data == true) {
          this.$message.success("新增成功！");
          this.getAllTags();
          this.getListProject();
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    // 保存标签
    submitTags() {
      this.$post(`/tag/addTagForProject`, {
        projectId: this.id,
        tagNames: [tag],
      }).then((res) => {
        if (res.data.data == true) {
          this.$message.success("新增成功！");
          this.getAllTags();
          this.getListProject();
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    // 删除标签
    submitDel(id) {
      this.$post(`/tag/deleteTag`, {
        id,
      }).then((res) => {
        if (res.data.data == true) {
          this.$message.success("删除成功！");
          this.getAllTags();
          this.getListProject();
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    // 标签弹窗
    showTagDialog(item, index) {
      this.visible = true;
      this.id = item.id;
      this.projectName = item.title;
      this.hasTags = this.tagsList[index];
      // // this.tagsList = item.tagIds
      this.currentProjectIndex = index;
    },
    delPro(item) {
      this.sendVisible = true;
      this.id = item.id;
    },
    // 删除项目
    confirmDel() {
      this.$post(`/wd/deleteProject`, {
        id: this.id,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("删除成功！");
          this.sendVisible = false;
          this.getListProject();
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    getListProject() {
      this.$get(`/wd/listProject?status=${this.status}&tagId=${this.tagId} `).then(
        (res) => {
          this.list = res.data.data;
          this.tagsList = [];
          this.list.map((item) => {
            this.tagsList.push(item.tagIds);
          });
          if (this.visible) {
            this.hasTags = this.tagsList[this.currentProjectIndex];
          }
        }
      );
    },
    changeTab(i) {
      this.isOpen = false;
      this.current = i;
      this.status = i + 1;
      this.getListProject();
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  ul {
    width: 100%;
    li {
      display: inline-block;
      padding-right: 50px;
      color: #bcbcbc;
      cursor: pointer;
      font-size: 20px;
      &.on {
        color: #000;
      }
    }
  }
  .scroll-box {
    height: calc(100vh - 120px);
    overflow-y: auto;
    overflow-x: visible;
    .itemBox {
      position: relative;
      margin-bottom: 35px;
      .childItem {
        background: #feffff;
        height: 0;
        overflow-y: hidden;
        margin-left: 72px;
        margin-right: 25px;
        margin-top: -6px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        &.opend {
          padding: 20px 0;
          height: auto;
          .item {
            max-height: 130px;
            overflow-y: auto;
            border-radius: 0;
          }
        }

        li {
          padding: 5px 0;
          padding-left: 50px;
          color: #000;
          position: relative;
          display: block;
          &:hover {
            background: #5fdca8;
            border-radius: 0;
            a {
              color: #fff;
            }
            &:before {
              background: #fff;
            }
          }
          a {
            font-size: 18px;
            font-weight: bold;
            display: block;
          }
          &:before {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #000;
            position: absolute;
            top: 50%;
            left: 20px;
          }
        }
      }
      .open {
        width: 50px;
        height: 50px;
        background: url(../../../static/images/arrow-right.png) no-repeat center;
        background-size: 15px 30px;
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;
        &.on {
          right: 60px;
          top: 50%;
          transform: rotate(90deg) translateX(-50%);
        }
      }
    }
    .item {
      margin-top: 10px;
      border-radius: 8px;
      position: relative;
      .delBox {
        height: 30px;
        width: 50px;
        position: absolute;
        left: 67px;
        top: 0px;
        cursor: pointer;
        background: url(../../../static/images/tagManage.png) no-repeat;
        background-size: contain;
        z-index: 2;
        &:hover .del {
          display: block;
        }
        .del {
          width: 100px;
          height: 40px;
          background: #fff;
          position: absolute;
          top: 20px;
          left: 30px;
          border-radius: 4px;
          display: none;
          span {
            height: 30px;
            line-height: 30px;
            display: inline-block;
            width: 100%;
            text-align: center;
            background: #feffff;
            color: #000;
            &:hover {
              background: #5fdca8;
              color: #fff;
            }
          }
        }
        i {
          transform: rotate(90deg);
        }
      }
    }
    .item + .item {
      margin-top: 20px;
    }
    .item:hover {
      h2 {
        color: #41c68e;
      }
    }
    .flower {
      width: 12vw;
      height: 8vw;
      min-width: 175px;
      min-height: 120px;
    }
    .bbox {
      width: 98%;
      .title {
        font-size: 30px;
        letter-spacing: 2px;
        margin-bottom: 10px;
      }
      > .flex {
        align-items: stretch;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        flex: 1;
        background: #f7f8f8;
        border-radius: 8px;
        overflow: hidden;
      }
    }
    .bold {
      span {
        color: #000;
        font-size: 30px;
        width: 70px;
        display: inline-block;
        text-align: right;
      }
    }
    h2 {
      transition: all 0.2s;
    }
    .el-icon-arrow-right {
      font-size: 36px;
      font-weight: bold;
      color: #000;
    }
    span {
      color: #bcbcbc;
    }
    .text {
      flex: 1;
      margin-left: -3px;
      padding: 20px;
      box-sizing: border-box;
      transition: all 0.2s;
    }
  }
  .list {
    width: 100%;
    .item {
      width: 23%;
      background: #f7f8f8;
      padding: 20px 0;
      margin-top: 20px;
      cursor: pointer;
    }

    .item + .item {
      margin-left: 2%;
    }
    .item:nth-child(4n + 1) {
      margin-left: 0;
    }
    &.show {
      display: block;
    }
  }
}
::v-deep .el-input {
  width: 300px;
}
::v-deep .el-button--primary.createProject {
  background-color: #000 !important;
  border-color: #000 !important;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  margin-right: 18px;
}
::v-deep .el-button--primary.mg-l-20 {
  margin-left: 10px;
  border-radius: 4px;
}
.tags .more_tag {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 15px;
}
.tags .box {
  position: relative;
}
.tags .box:hover .tag_box {
  display: block;
}
.tags .tag_box {
  width: 400px;
  height: 300px;
  overflow: auto;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 100%;
  top: -5px;
  z-index: 2;
  padding: 15px;
  box-sizing: border-box;
  display: none;
  border-color: transparent;
}
.tags .tag_box p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.tags .el-tag {
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  background: #f6f6f6;
  color: #41c68e;
  border-radius: 10px;
  font-weight: bold;
  margin-right: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
  font-size: 16px;
  cursor: pointer;
}
.tags .el-tag.on {
  background: #41c68e;
  color: #fff;
}
.el-tag {
  border-color: transparent;
}
</style>
