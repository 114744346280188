<template>
  <div class="info">
    <div class="nav flex space-between align-center">
      <div
        class="left flex align-center"
        @click="$router.push('/project/page-list/projectList')"
      >
        <el-image
          class="arrow"
          :src="require('@/static/images/left-arrow.png')"
          lazy
        ></el-image>
        <p class="mg-l-20">返回</p>
      </div>
      <div class="right">
        <el-button
          v-if="info.status != 3 && !$route.query.type"
          :type="info.status != '2' ? 'primary' : 'info'"
          class="sold"
          @click="del($route.query.id, 4)"
          >{{ info.status != "2" ? "休眠" : "解除休眠" }}</el-button
        >
        <el-button
          class="mg-l-20 finish"
          v-if="$route.query.type == 'child'"
          type="info"
          @click="delChildVisible = true"
          >删除</el-button
        >
        <el-button
          class="mg-l-20 finish"
          v-if="info.status == 1"
          type="primary"
          @click="del($route.query.id, 6)"
          >完成</el-button
        >
      </div>
    </div>
    <div class="infomation" v-if="Object.keys(info).length > 0">
      <div class="layout" v-if="showLayout" @click="isXM"></div>
      <h2
        class="mg-b-20"
        style="
          font-size: 50px;
          font-weight: bold;
          color: #41c68e;
          letter-spacing: 4px;
          margin-top: 10px;
        "
      >
        {{ info.title }}
      </h2>
      <p style="color: #bcbcbc">
        {{ info.gmtCreated }}
        <span class="childTag" v-if="$route.query.type">子项目</span>
      </p>
      <el-tabs v-model="activeName">
        <el-tab-pane label="总览" name="first">
          <span slot="label">
            <img
              class="first"
              v-if="activeName == 'first'"
              src="../../../static/images/icon_6_1.png"
              alt=""
            />
            <img class="first" v-else src="../../../static/images/icon_6.png" alt="" />

            总览
          </span>
          <h4 class="mg-t-20 flex space-between" style="font-size: 20px">
            <span style="font-size: 20px">计划：</span>
            <span
              style="
                color: #41c68e;
                text-decoration: underline;
                cursor: pointer;
                font-size: 18px;
                font-weight: normal;
              "
              @click="
                $router.push(
                  `/project/page-list/createProject?id=${$route.query.id}&type=${pageType}&is=edit`
                )
              "
              >编辑</span
            >
          </h4>
          <div class="flex mg-t-20 flex-wrap">
            <el-tag
              :type="item.status == 2 ? 'success' : 'info'"
              class="text-center mg-b-20"
              v-for="item in info.nodeList"
              @click="finishTag(item)"
              :key="item.id"
              >{{ item.name }}</el-tag
            >
          </div>
          <h4 class="mg-t-30" style="font-size: 20px">添加技能：</h4>
          <div class="add mg-t-20 flex align-center">
            <el-input
              v-model="name"
              class="mg-r-20 skill"
              type="text"
              maxlength="6"
              show-word-limit
            ></el-input>
            <el-button type="primary" class="addSkill" @click="addProjectSkill"
              >添加</el-button
            >
          </div>
          <div class="mg-t-20 skillItemBox">
            <div class="box flex" :style="{ width: info.skillList.length * 130 + 'px' }">
              <div v-for="item in info.skillList" class="skillItem">
                <i class="el-icon-close" @click="del(item.id, 1)"></i>
                <el-image :src="item.img"></el-image>
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
          <h4 class="mg-t-30" style="font-size: 20px">反思：</h4>
          <div class="flex mg-t-20 space-between align-center">
            <el-input
              v-model="content"
              type="textarea"
              maxlength="200"
              show-word-limit
              class="mg-r-20 skill"
            ></el-input>
            <el-button type="primary" class="summarize" @click="addProjectKnowledge"
              >完成</el-button
            >
          </div>
          <div class="commentList">
            <div class="item mg-t-20" v-for="item in info.knowledgeList">
              <div class="flex space-between">
                <span class="color-999">{{ item.gmtCreated }}</span>
                <span class="color-999 del" @click="del(item.id, 2)">删除</span>
              </div>
              <div class="comment">
                {{ item.content }}
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="研究" name="second">
          <span slot="label">
            <img
              class="second"
              v-if="activeName == 'second'"
              src="../../../static/images/icon_7_1.png"
              alt=""
            />
            <img class="second" v-else src="../../../static/images/icon_7.png" alt="" />
            研究
          </span>
          <ul>
            <li v-for="(item, index) in studyList">
              <div class="flex space-between">
                <span>{{ item.gmtCreated }}</span>
                <span style="cursor: pointer" @click="del(item.id, 7)">删除</span>
              </div>
              <a href="#" @click="editResearch(item)">
                <div class="flex space-between align-center">
                  <p class="title">{{ item.title }}</p>
                  <img src="../../../static/images/arrow-right.png" alt="" />
                </div>
              </a>
            </li>
          </ul>
          <div class="text-center mg-t-20">
            <el-button type="primary" @click="addR">提出问题</el-button>
            <p class="mg-t-20" style="font-size: 14px; color: #999">
              提出问题、作出假设、制定计划、实施计划、得出结论、表达交流
            </p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog title="" :visible.sync="sendVisible" width="40%" center>
      <p style="font-size: 24px; text-align: center">{{ tips }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmDel">确 定</el-button>
        <el-button @click="sendVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="" :visible.sync="delChildVisible" width="40%" center>
      <p style="font-size: 24px; text-align: center; margin-top: 0; margin-bottom: 20px">
        确定删除此子项目？
      </p>
      <p style="font-size: 16px; color: red; text-align: center; margin-bottom: 10px">
        删除后该项目无法找回
      </p>
      <p class="text-center">
        <span slot="footer" class="dialog-footer text-center">
          <el-button type="primary" @click="confirmDelChild">确 定</el-button>
          <el-button @click="delChildVisible = false">取 消</el-button>
        </span>
      </p></el-dialog
    >
    <research
      :visible="visible"
      :researchTitle="researchTitle"
      :researchContent="researchContent"
      @updateVisible="updateVisible"
      @addResearch="addResearch"
    />
  </div>
</template>
<script>
import research from "../components/research.vue";

export default {
  name: "seedInfo",
  components: { research },
  data() {
    return {
      pageType: "",
      researchId: "",
      researchType: "",
      researchContent: "",
      researchTitle: "",
      visible: false,
      delChildVisible: false,
      sendVisible: false,
      info: {},
      name: "",
      content: "",
      delId: "",
      tips: "确定删除此条知识？",
      type: "",
      showLayout: false,
      activeName: "first",
      studyList: [],
    };
  },
  created() {
    this.getStudyList();
    if (this.$route.query.type) {
      this.pageType = "child";
    } else {
      this.pageType = "parent";
    }
    this.getProjectInfo();
  },
  methods: {
    confirmDelChild() {
      this.$post(`/project_sub/deleteProjectSub`, {
        id: this.$route.query.id,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("删除成功！");
          this.delChildVisible = false;
          let i = setTimeout(() => {
            this.$router.push("/project/page-list/projectList");
            clearTimeout(i);
          }, 1000);
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    updateVisible(v) {
      this.visible = v;
    },
    editResearch(item) {
      this.visible = true;
      this.researchId = item.id;
      this.researchType = "edit";
      this.researchContent = item.content;
      this.researchTitle = item.title;
    },
    addR() {
      this.visible = true;
      this.researchType = "add";
    },
    // 新增研究
    addResearch(arr) {
      if (this.researchType == "add") {
        this.$post(`/wd/addProjectResearch`, {
          projectId: this.$route.query.id,
          title: arr[0],
          content: arr[1],
        }).then((res) => {
          if (res.data.data == true) {
            this.$message.success("新增成功！");
            this.visible = false;
          } else {
            this.$message.error(res.data.msg);
          }
          this.getStudyList();
        });
      } else {
        this.$post(`/wd/editProjectResearch`, {
          id: this.researchId,
          projectId: this.$route.query.id,
          title: arr[0],
          content: arr[1],
        }).then((res) => {
          if (res.data.data == true) {
            this.$message.success("编辑成功！");
            this.visible = false;
          } else {
            this.$message.error(res.data.msg);
          }
          this.getStudyList();
        });
      }
    },
    // 研究列表
    getStudyList() {
      this.$get(
        `/wd/listProjectResearchByProjectId?projectId=${this.$route.query.id}`
      ).then((res) => {
        this.studyList = res.data.data;
      });
    },
    getProjectInfo() {
      if (this.pageType == "parent") {
        this.$get(`/wd/getProjectDetail?projectId=${this.$route.query.id}`).then(
          (res) => {
            this.info = res.data.data;
          }
        );
      } else {
        this.$get(`/project_sub/getProjectDetail?projectId=${this.$route.query.id}`).then(
          (res) => {
            this.info = res.data.data;
          }
        );
      }
    },
    handleClick(v) {
      console.log(v);
      this.activeName = v;
    },
    // 添加技能
    addProjectSkill() {
      if (this.info.status == 2) {
        this.$message.warning("项目处于休眠中，不能操作！");
        return false;
      }
      this.$post(`/wd/addProjectSkill`, {
        projectId: this.$route.query.id,
        name: this.name,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("添加成功！");
          this.getProjectInfo();
          this.name = "";
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    // 添加知识
    addProjectKnowledge() {
      if (this.info.status == 2) {
        this.$message.warning("项目处于休眠中，不能操作！");
        return false;
      }
      this.$post(`/wd/addProjectKnowledge`, {
        projectId: this.$route.query.id,
        content: this.content,
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("添加成功！");
          this.getProjectInfo();
          this.content = "";
        } else {
          this.$message.error(res.data.errorMessage);
        }
      });
    },
    //
    finishTag(item) {
      if (item.status != 2) {
        // 完成节点
        this.$post(`/wd/finishProjectNode`, {
          id: item.id,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success(`获得${item.genieScore}灵`);
            this.$parent.getLingLanZhiJingPlant();
            this.getProjectInfo();
          } else {
            this.$message.error(res.data.errorMessage);
          }
        });
      }
    },
    // 删除
    del(id, type, status) {
      if (this.info.status == 2 && type != 4 && type != 5) {
        this.$message.warning("项目处于休眠中，不能操作！");
        return false;
      }
      console.log(id);
      this.sendVisible = true;
      this.delId = id;
      this.type = type;
      if (type == 1) {
        this.tips = "确定删除此技能？";
      } else if (type == 2) {
        this.tips = "确定删除此条知识？";
      } else if (type == 7) {
        this.tips = "确定删除此研究？";
      } else if (type == 3 && status == 1) {
        this.tips = "此项节点确认已完成吗？";
      } else if (type == 3 && status == 2) {
        this.sendVisible = false;
        this.$message.warning("此节点已完成");
      } else if (type == 4) {
        if (this.info.status == 1) {
          this.tips = "确认休眠此项目？";
        } else if (this.info.status == 2) {
          this.tips = "确认继续此项目？";
        }
      } else if (type == 6) {
        this.tips = "确认此项目已完成？";
      }
    },
    confirmDel() {
      if (this.type == 1) {
        // 删除技能
        this.$post("/wd/deleteProjectSkill", {
          id: this.delId,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("删除成功！");
            this.getProjectInfo();
            this.sendVisible = false;
          } else {
            this.$message.error(res.data.errorMessage);
          }
        });
      } else if (this.type == 2) {
        // 删除知识
        this.$post("/wd/deleteProjectKnowledge", {
          id: this.delId,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("删除成功！");
            this.getProjectInfo();
            this.sendVisible = false;
          } else {
            this.$message.error(res.data.errorMessage);
          }
        });
      } else if (this.type == 7) {
        // 删除研究
        this.$post("/wd/deleteProjectResearch", {
          id: this.delId,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("删除成功！");
            this.getStudyList();
            this.sendVisible = false;
          } else {
            this.$message.error(res.data.errorMessage);
          }
        });
      } else if (this.type == 3) {
      } else if (this.type == 4 || this.type == 6) {
        // 修改项目状态  1 进行  2休眠  3完成
        let status = "";
        if (this.type == 4 && this.info.status == 1) {
          status = 2;
        } else if (this.type == 4 && this.info.status == 2) {
          status = 1;
        } else {
          status = 3;
        }
        this.$post(`/wd/updateProjectStatus`, {
          projectId: this.delId,
          status,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("操作成功！");
            this.getProjectInfo();
            this.sendVisible = false;
          } else {
            // this.$message.error(res.data.errorMessage);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  height: 100%;
  .childTag {
    padding: 5px 10px;
    background: #59b5d2;
    color: #fff;
    display: inline-block;
    margin-left: 20px;
    border-radius: 4px;
  }
  .nav {
    .arrow {
      width: 10px;
      height: 20px;
      font-size: 18px;
    }
    .left {
      cursor: pointer;
    }
    .sold {
      background: none !important;
      color: #41c68e;
      &.el-button--info {
        color: #fff;
        background: #999 !important;
      }
    }
  }

  .infomation {
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: visible;
    padding-right: 20px;
    padding-top: 10px;
    box-sizing: border-box;
    position: relative;
    padding-left: 10px;
    .first {
      width: 20px;
      height: 20px;
      vertical-align: sub;
    }
    .second {
      width: 25px;
      height: 25px;
      vertical-align: sub;
    }
    .el-tabs {
      margin-top: 20px;
    }
    ::v-deep .el-tabs__item span {
      font-size: 18px !important;
      font-weight: bold;
      color: #999;
    }
    .el-tabs__item.is-active span {
      color: #000;
    }
    .layout {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
    .el-input {
      width: 300px;
    }

    .el-tag {
      font-size: 16px;
      width: 32%;
    }

    .el-tag + .el-tag {
      margin-left: 2%;
    }
    .el-tag:nth-child(3n + 1) {
      margin-left: 0;
    }
    .comment {
      padding: 20px;
      background: #f7f7f8;
      border-radius: 4px;
      margin-top: 20px;
      line-height: 26px;
      margin-bottom: 20px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .del {
      cursor: pointer;
      &:hover {
        color: #666;
      }
    }
  }
}
ul li {
  margin-top: 10px;
}
ul li span {
  color: #999;
}
ul li a {
  display: block;
  padding: 20px;
  border-radius: 10px;
  background: #f7f8f8;
  margin-bottom: 30px;
  margin-top: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}
ul li .title {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 4px;
}
ul li img {
  width: 10px;
  height: 20px;
}
::v-deep .el-tabs__active-bar {
  background: #41c68e;
  height: 3px;
  border-radius: 15px;
}
::v-deep .el-button--primary {
  background-color: #41c68e !important;
  border-color: #41c68e !important;
  padding: 10px 20px;
}
.el-tag {
  width: 30%;
  padding: 10px 0;
  height: auto;
  font-size: 16px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: none;
}
.el-tag.el-tag--info {
  color: #000;
  word-break: break-all;
  white-space: break-spaces;
  padding: 15px;
  height: 70px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-button + .el-button {
  margin-left: 20px;
}
::v-deep .el-textarea__inner {
  height: auto !important;
  min-height: 300px !important;
}
::v-deep .skill .el-textarea__inner {
  height: 100px !important;
  min-height: 100px !important;
}
.el-tag {
  color: #000;
  position: relative;
}
.skillItem {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}
.skillItem .el-icon-close {
  top: 5px;
  right: 5px;
  position: absolute;
  background: red;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  top: -5px;
  right: -5px;
  position: absolute;
  width: 17px;
  height: 17px;
  line-height: 17px;
}
::v-deep .el-tag.el-tag--success {
  background: linear-gradient(53deg, #ffb800 0%, #ff8b04 31.26%, #ed0404 97.45%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .finish.el-button--primary {
  margin-right: 20px;
  background: linear-gradient(
    53deg,
    #ffb800 0%,
    #ff8b04 31.26%,
    #ed0404 97.45%
  ) !important;
  border-color: transparent !important;
}
::v-deep .summarize.el-button--primary {
  height: 38px;
}
::v-deep .addSkill.el-button--primary {
  padding: 5px 20px;
}
.skillItemBox {
  overflow-x: auto;
  padding: 20px 0;
}
::v-deep .el-tabs__content {
  overflow: visible;
}
.skillItem {
  width: 120px;
  background: #f7f8f8;
  border-radius: 8px;
  text-align: center;
  position: relative;
  p {
    margin-bottom: 15px;
  }
}
.skillItem .el-image {
  width: 50px;
  height: 50px;
  display: block;
  margin: 15px auto;
}
.skillItem + .skillItem {
  margin-left: 3%;
}

::v-deep .skill .el-input__inner,
::v-deep .skill .el-textarea__inner {
  border-radius: 0;
  border: none;
  background: #f7f7f8;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  background: none;
}
::v-deep .el-tag .el-icon-close::before {
  background: red;
  border-radius: 50%;
  color: #fff;
}
</style>
