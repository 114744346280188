var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main flex space-between"},[_c('div',{staticClass:"profile flex flex-column"},[_c('div',{staticClass:"top flex"},[_c('div',{staticClass:"left"},[_c('el-image',{staticClass:"avatar",attrs:{"src":_vm.userInfo.avatar,"lazy":""}})],1),(Object.keys(_vm.userInfo).length > 0)?_c('div',{staticClass:"right mg-l-20"},[_c('div',{staticClass:"rightBox"},[_c('div',{staticClass:"flex flex-column space-arround infoBox"},[_c('div',{staticClass:"line flex space-between align-center"},[_c('p',[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("星民：")]),_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.userInfo.name))])]),_c('el-button',{staticClass:"edit",attrs:{"type":"text"},on:{"click":function($event){_vm.setVisible = true}}},[_vm._v("编辑")])],1),_c('div',{staticClass:"line"},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("证件号：")]),_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.userInfo.idCardNo))])]),_c('div',{staticClass:"line flex space-between align-center"},[_c('p',[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("阶段：")]),_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.userInfo.genieStageDict))])])])]),_c('div',{staticClass:"progress-container"},[_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress-fill",style:({
                  width:
                    (
                      _vm.userInfo.memberCapitalDO.totalGenieScore /
                      _vm.userInfo.arriveNextStageScore
                    ).toFixed(2) + '%',
                })}),_c('div',{staticClass:"fill"},[_vm._v(" "+_vm._s(_vm.userInfo.arriveNextStageScore)+" / "+_vm._s(_vm.userInfo.memberCapitalDO.totalGenieScore)+" ")])])])])]):_vm._e()]),_c('el-button',{staticClass:"quit",attrs:{"type":"text"},on:{"click":_vm.loginOut}},[_vm._v("退出登录")]),_c('div',{staticClass:"intro",staticStyle:{"text-align":"center"}},[_vm._v("成功连接无限的生长之诗—歌若思🌱")]),(Object.keys(_vm.userInfo).length > 0)?_c('div',{staticClass:"bottom mg-t-20",staticStyle:{"margin-top":"12px"}},[_c('p',{staticStyle:{"font-size":"18px"}},[_vm._v(" 星灵："),_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.userInfo.starGenieName))])]),_c('div',{staticClass:"img mg-t-20"})]):_vm._e()],1),_c('div',{staticClass:"content"},[_c('router-view')],1),_c('el-dialog',{attrs:{"title":"","visible":_vm.quitVisible,"width":"40%","center":""},on:{"update:visible":function($event){_vm.quitVisible=$event}}},[_c('p',{staticStyle:{"font-size":"24px","text-align":"center"}},[_vm._v("确定退出该账号？")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.confirmQuit}},[_vm._v("确 定")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.quitVisible = false}}},[_vm._v("取 消")])],1)]),(Object.keys(_vm.userInfo).length > 0)?_c('Set',{attrs:{"setVisible":_vm.setVisible,"info":_vm.userInfo},on:{"updateVisible":_vm.updateVisible,"updateInfo":_vm.updateInfo,"reLogin":_vm.reLogin}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }