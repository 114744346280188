import axios from "axios";
import JsonBigint from 'json-bigint'
import { Message } from "element-ui";
const request = axios.create({
  // baseURL: 'http://47.76.52.13:9000/api',
  // baseURL: 'http://394z349840.goho.co/api',
  baseURL: 'http://www.growthx-verse.com/api',
  timeout: 10000
})
request.defaults.transformResponse = [
  function (data) {
      const json = JsonBigint({
        storeAsString:true
      })
      const res = json.parse(data)
      return res
  }
]
request.interceptors.request.use(
  function(config) {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token') || ''}`
    config.headers['X-Access-Token'] = localStorage.getItem("token") || ''
    config.headers['Content-Type'] = "application/json"
    config.headers.token = localStorage.getItem('token') 
    
    return config
},function(err) {
    return Promise.reject(err)
})

request.interceptors.response.use(
  function(response) {
    if (!response.data.success) {
      if (response.data.errorCode == '401') {
        Message.error('登录已失效，请重新登录')
        localStorage.clear()
         let i = setTimeout(() => {
          location.href = '/home'
          clearTimeout(i)
        },1000)
      } else {
        Message.error(response.data.errorMessage)
      }
    }
    return response
},function(err) {
  Message.error(err.response.data.error)
  // if (err.name == 'SyntaxError') {
  //   Message.error('请重新登录')
  //   localStorage.clear()
  //   let i = setTimeout(() => {
  //       location.href = '/home'
  //       clearTimeout(i)
  //     },1000)
  // } else {
  //   localStorage.clear()
  //   Message.error(err.response.data.error)
  // }
  //   console.log('返回', err)
    return Promise.reject(err)
})

export function get(url, params) {
  return request.get(url)
}

export function post(url, data) {
  return request.post(url,{
    ...data
  })
}

export function put(url, data) {
  return request.put(url,{
    data
  })
}

export function del(url, data) {
  return request.delete(url,{
    data
  })
}