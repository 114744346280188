<template>
  <div>
    <div class="container" style="padding: 0">
      <div class="flex section" style="padding-top: 0">
        <div class="home">
          <div class="main">
            <!-- <header class="text-right flex flex-end">
            <a
              href="https://docs.qq.com/aio/DYWVxYklHbm93RkVp"
              target="_blank"
              class="aboutus color-main font-18 mg-r-20"
              >关于我们</a
            >
          </header> -->
            <div class="text-container">
              <div class="title sy-bold" style="font-weight: bolder">歌若思·问道</div>
              <p class="green1" style="font-weight: bolder">
                一款简单、有趣的项目制学习工具
              </p>
              <p class="green2">致力于打造终身学习生态中心</p>

              <div class="btn" v-if="isLogin == '' || isLogin == null">
                <el-button
                  type="primary"
                  @click.sync="visible = true"
                  style="font-weight: bolder"
                  >立即体验
                  <el-image
                    :src="require('../../static/images/arrow_right 1_.png')"
                  ></el-image
                ></el-button>
                <!-- <el-button @click="registerVisible = true">注册</el-button> -->
              </div>
            </div>
          </div>
        </div>
        <div class="greenBj"></div>
      </div>
      <div class="section">
        <div class="step">
          <h1>项目制学习形态</h1>
          <p>
            游戏化设计，将所有学习行为划归到一个个项目中，不断给予用户正向反馈，将学习后收获的无形知识变为固态的项目结果
          </p>
        </div>
        <div v-for="(item, index) in 6" :key="index">
          <img
            class="arrow"
            :class="'arrow' + (index + 1)"
            src="../../static/images/Vector.png"
            alt=""
          />
        </div>
        <div class="item item1">
          <img src="../../static/images/home1.png" alt="" />
          <p>1、确定主题，发起项目</p>
        </div>
        <div class="item item2">
          <img src="../../static/images/home2.png" alt="" />
          <p>2、依据目标、设定计划</p>
        </div>
        <div class="item item3">
          <img src="../../static/images/home3.png" alt="" />
          <p>3、出现问题，学习解决</p>
        </div>
        <div class="item item4">
          <img src="../../static/images/home4.png" alt="" />
          <p>4、完成计划，获取灵力</p>
        </div>
        <div class="item item5">
          <img src="../../static/images/home5.png" alt="" />
          <p>5、灵力种植物，收获果实</p>
        </div>
        <div class="item item6">
          <img src="../../static/images/home6.png" alt="" />
          <p>6、售卖果实，收集更多植物</p>
        </div>
      </div>

      <Register :registerVisible.sync="visible" @updateVisible="updateVisible" />
    </div>
    <div class="section flex">
      <div class="left">
        <div class="layout"></div>
        <div class="text">
          <h1>奇点学院</h1>
          <p>提供多元化项目制学习教程<br />成就每一个可能</p>
        </div>
      </div>
      <div class="right">
        <div class="text">
          <div class="img">
            <img src="../../static/images/section3_logo.png" alt="" />
          </div>
          <h1>生成 · 存在</h1>
          <h5>Gengrate·Exist</h5>
          <p>
            “奇点学院”是一个免费、开放的学习社区，每一个人都可以在这里分享自己创建的项目及其子集，你可以分享自己的奇思妙想，获取专业的职业发展路径，也可以学习到他人的技术经验。
          </p>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="fire"></div>
      <div class="text-5">
        <h1>打造终身学习生态中心</h1>
        <p>
          每一家教育公司都像是一颗星球，而我们要搭建一个飞船/空间站，它是这片宇宙的枢纽站，它开放，连接一切，帮助每个人更好的走过一生的旅程。
        </p>
      </div>
      <div class="item-box item-box1">
        <h1 class="flex align-center">
          <img src="../../static/images/green1.png" alt="" />为公理而教育
        </h1>
        <p>
          通过讲故事的形式引导人们向善，在公理的基础上每个人成为更好的自己，世界就会变成更好的世界。
        </p>
      </div>
      <div class="item-box item-box2">
        <h1 class="flex align-center">
          <img src="../../static/images/green2.png" alt="" />为自我学习
        </h1>
        <p>
          教育是我要你怎么怎么样，而学习在于个人，即我要怎么怎么样，以人为本，通过学习改变自身的命运。
        </p>
      </div>
      <div class="item-box item-box3">
        <h1 class="flex align-center">
          <img src="../../static/images/green3.png" alt="" />个性化AI
        </h1>
        <p>
          在人工智能的时代背景下，我们有条件让教育更加平等，让每一个人都能获得个性化的指导，充分实现每个人的价值。
        </p>
      </div>
      <div class="item-box item-box4">
        <h1 class="flex align-center">
          <img src="../../static/images/green4.png" alt="" />实践中学习
        </h1>
        <p>
          鼓励人们动手去做，不做理念派，真正的能够去创造一些有价值的东西，而不是整虚的，要将知识和技能实体化。
        </p>
      </div>
      <div class="item-box item-box5">
        <h1 class="flex align-center">
          <img src="../../static/images/green5.png" alt="" />学习游戏化
        </h1>
        <p>
          学习是一件反人性的事情，而游戏则是一种帮助人们创建美好体验的方式，二者是可以相互结合的。
        </p>
      </div>
    </div>
    <div class="section">
      <div class="right-last"></div>
      <img class="house" src="../../static/images/house1.png" alt="" />
      <div class="text-last">
        <h1>歌若思生长实验室</h1>
        <p class="mg-t-20 mg-b-20">
          专注于研究教育学、心理学、生物学、产品设计、游戏、元宇宙、人工智能和终身学习，以人为本，弘扬真善美，旨在培育能够为社会创造增量价值的人才。
        </p>
        <h3>联系/加入我们</h3>
        <p class="flex align-center">
          <img src="../../static/images/wechat.png" alt="" /><i>微信：</i
          ><span>18063199431</span>
        </p>
        <p class="flex align-center">
          <img src="../../static/images/Email.png" alt="" /><i>邮箱：</i
          ><span>1598133107@qq.com</span>
        </p>
        <a
          href="https://docs.qq.com/aio/DYWVxYklHbm93RkVp"
          target="_blank"
          class="aboutus color-main font-18 mg-r-20"
          >了解更多</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import Register from "./components/register.vue";
export default {
  name: "home",
  components: {
    Register,
  },
  data() {
    return {
      visible: false,
      isLogin: "",
      items: ["Item 1", "Item 2", "Item 3"],
      showDeleteBtn: false,
      deleteIndex: null,
    };
  },
  created() {
    this.isLogin = localStorage.getItem("token");
    const zoom = window.screen.width / 1920;
    const size = zoom * 16;
    document.documentElement.style.fontSize = size + "px";
    console.log("设置字体大小", window.screen.width);
  },
  methods: {
    updateVisible(bool) {
      this.visible = bool;
      location.reload();
    },
    showContextMenu(event, index) {
      event.preventDefault();
      this.showDeleteBtn = true;
      this.deleteIndex = index;
    },
    deleteItem(index) {
      this.items.splice(index, 1);
      this.hideContextMenu();
    },
    hideContextMenu() {
      this.showDeleteBtn = false;
      this.$nextTick(() => {
        const deleteBtn = document.querySelector(".delete-btn");
        deleteBtn.style.display = "none";
      });
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  background: #f5faf5;
}
.section {
  height: 100vh;
  width: 100vw;
  background: #f5faf5;
  padding-top: 50px;
  .right-last {
    width: 25vw;
    background: #edffe9;
    position: absolute;
    right: 0;
    top: 50px;
    height: 100vh;
  }
  .house {
    width: 40%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .text-last {
    position: absolute;
    left: 80px;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    h1 {
      font-size: 5rem;
    }
    p,
    i,
    span {
      font-size: 1.6rem;
      line-height: 2.6rem;
      color: #9d9d9d;
      font-style: normal;
    }
    span {
      color: #000;
      display: inline-block;
      margin-left: 5px;
    }
    h3 {
      font-size: 2rem;
      color: #41c68e;
      margin-bottom: 10px;
    }
    img {
      width: 30px;
      height: 30px;
      display: block;
      margin-right: 10px;
    }
  }
  .text-5 {
    width: 50%;
    padding: 20px;
    h1 {
      font-size: 4.4rem;
      color: #41c68e;
      margin-bottom: 10px;
    }
    p {
      width: 85%;
      line-height: 3rem;
      font-size: 1.5rem;
      color: #9d9d9d;
    }
  }
}
.home {
  height: 100vh;
  width: 60%;
  background: #f5faf5;
}
.greenBj {
  width: 40%;
  background: #41c68e;
  position: relative;
  &::before {
    content: "";
    width: 67px;
    height: 67px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.green1 {
  color: #41c68e;
  font-size: 3.2rem;
  margin-top: 20px;
}
.green2 {
  font-size: 3rem;
  color: #5ade39;
}
.aboutus {
  display: inline-block;
  font-weight: bold;
  margin-top: 30px;
  padding: 5px 20px;
  background: #41c68e;
  color: #fff;
  border-radius: 5px;
  font-size: 1.6rem;
  line-height: 2.6rem;
}
.main {
  height: 100vh;
  padding: 20px 0;
  padding-top: 50px;
  box-sizing: border-box;
  position: relative;
  .text-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 80px;
  }
}
header {
  width: 100%;
}
.logo {
  width: 20vh;
  height: 20vh;
  margin-top: 10vh;
}
.title {
  font-size: 8rem;
  color: #000;
  font-family: "sy-medium";
}
.content {
  width: 60%;
  line-height: 30px;
  font-weight: 500;
  margin: 30px auto;
  margin-top: 3vh;
  font-size: 16px;
}
.btn {
  margin-top: 5vh;
}
::v-deep .el-button {
  span {
    font-size: 1.4rem !important;
  }
  .el-image {
    width: 12px;
    vertical-align: top;
    display: inline-block;
    margin-left: 10px;
  }
}
::v-deep .el-button--primary {
  background-color: #41c68e !important;
  border-color: #41c68e !important;
}
#container {
  position: relative;
}

.delete-btn {
  position: absolute;
  top: 0;
  right: -50px;
  background-color: #f00;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  display: none;
}
.section {
  position: relative;
  .item-box {
    position: absolute;
    padding: 20px;
    background: #fff;
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1.4rem;
    }
  }
  .item-box1 {
    top: 8vh;
    right: 20px;
    width: 40vw;
  }
  .item-box2 {
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 25vw;
  }
  .item-box3 {
    bottom: 20px;
    right: 20px;
    width: 25vw;
  }
  .item-box4 {
    bottom: 20px;
    left: 20px;
    width: 25vw;
  }
  .item-box5 {
    top: 50%;
    left: 20px;
    width: 25vw;
    transform: translateY(-50%);
  }
  .fire {
    width: 50%;
    height: 80vh;
    background: url(../../static/images/fireGreen.png) no-repeat;
    background-size: contain;
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
  }
  .arrow {
    position: absolute;
  }
  .arrow1 {
    left: 28%;
    top: 10vh;
  }
  .arrow2 {
    right: 28%;
    top: 10vh;
  }
  .arrow3 {
    right: 10vw;
    top: 45vh;
    transform: rotate(90deg);
  }
  .arrow4 {
    right: 28%;
    bottom: 15vh;
    transform: rotate(-180deg);
  }
  .arrow5 {
    left: 28%;
    bottom: 15vh;
    transform: rotate(-180deg);
  }
  .arrow6 {
    left: 10vw;
    top: 45vh;
    transform: rotate(-90deg);
  }
  .step {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    h1 {
      font-size: 8rem;
      color: #41c68e;
    }
    p {
      font-size: 1.6rem;
      color: #5ade39;
      width: 55%;
      margin: 0 auto;
    }
  }
  .item {
    position: absolute;
    text-align: center;
    img {
      display: inline-block;
    }
    p {
      text-align: center;
      font-size: 2rem;
    }
  }
  .item1 {
    top: 30px;
    left: 30px;

    img {
      margin-top: -40px;
      width: 15vw;
    }
    p {
      margin-top: -30px;
    }
  }
  .item2 {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 12vw;
      margin-top: -20px;
    }
  }
  .item3 {
    top: 30px;
    right: 30px;
    img {
      width: 15vw;
      margin-top: -50px;
    }
    p {
      margin-top: -25px;
    }
  }
  .item4 {
    bottom: 30px;
    right: 30px;
    img {
      width: 15vw;
      margin-top: -50px;
    }
    p {
      margin-top: -25px;
    }
  }
  .item5 {
    bottom: 30px;
    left: 50%;
    transform: translateX(-40%);
    img {
      width: 12vw;
      margin-bottom: 30px;
    }
    p {
      margin-top: -25px;
    }
  }
  .item6 {
    bottom: 30px;
    left: 30px;
    img {
      width: 15vw;
      margin-top: -50px;
    }
    p {
      margin-top: -25px;
    }
  }
  .left {
    width: 60%;
    height: 100%;
    background: url(../../static/images/section3_bi.jpg) no-repeat center;
    background-size: cover;
    position: relative;
    .layout {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      left: 0;
      top: 0;
    }
    .text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 40px;
      h1 {
        color: #fff;
        font-size: 7rem;
        margin-bottom: 30px;
      }
      p {
        color: #fff;
        font-size: 3.6rem;
      }
    }
  }
  .right {
    width: 40%;
    text-align: center;
    position: relative;
    .text {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .img {
      margin-bottom: 30px;
      img {
        width: 15vw;
      }
    }
    h1 {
      font-size: 4rem;
      color: #41c68e;
    }
    h5 {
      font-size: 2rem;
      color: #5ade39;
      margin-bottom: 20px;
    }
    p {
      font-size: 1.8rem;
      width: 90%;
      margin: 0 auto;
      margin-top: 60px;
    }
  }
}
</style>
