<template>
  <div id="app">
    <HeaderNav v-if="isShow && $route.path != '/home'" />
    <router-view />
  </div>
</template>
<script>
import HeaderNav from "./components/Header-nav.vue";
export default {
  components: {
    HeaderNav,
  },
  data() {
    return {
      isLogin: "",
      isShow: false,
    };
  },
  created() {
    this.isLogin = localStorage.getItem("token");
    window.addEventListener("storage", this.handleLocalStorageChange);
    console.log(this.isLogin);
  },
  watch: {
    isLogin(n) {
      if (n != null && n != "") {
        this.isShow = true;
      }
    },
  },
  methods: {
    handleLocalStorageChange() {
      this.isShow = true;
      console.log(222222);
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("./static/css/common.css");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::v-deep .el-button--default:hover {
  background-color: transparent;
  border-color: #eee;
  color: #999;
}
</style>
